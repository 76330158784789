<template>
<div :class="[!app.recurrence ? '' : 'opacity-30 pointer-events-none', 'transition-all duration-300']">
    <nav class="flex mb-4 h-8" aria-label="Breadcrumb">
        <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
            <li class="flex">
                <div class="flex items-center">
                    <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                        <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                        <span class="sr-only">Home</span>
                    </router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <router-link to="/dashboard/orders" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Menu zamówień</router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <div class="ml-4 text-xs font-medium text-gray-400 cursor-default select-none" aria-current="page">Zamówienia oczekujące</div>
                </div>
            </li>
        </ol>
    </nav>
    <div>
        <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
        <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
            <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
        </select>
        </div>
        <div class="hidden sm:block">
        <div class="border-b border-gray-200">
            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                <a @click.prevent="changeTab(tab.name, indx)" v-for="(tab, indx) in tabs" :key="tab.name" :class="[isDisabled ? 'pointer-events-none' : '', tab.current ? 'border-indigo-500 text-indigo-600 pointer-events-none' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'select-none cursor-pointer group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm']" :aria-current="tab.current ? 'page' : undefined">
                    <ViewGridAddIcon v-if="tab.current" :class="[tab.current ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-2 h-5 w-5']" />
                    <ViewGridIcon v-else :class="[tab.current ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-2 h-5 w-5']" />
                    <span class="font-semibold uppercase text-xs">{{ tab.name }}</span>
                </a>
            </nav>
        </div>
        </div>
    </div>
    
    <Transition name="fade">
    <div class="border-l-4 border-yellow-400 bg-yellow-50 px-4 py-5 my-4 select-none" v-if="apiloLoaded">
        <div class="flex items-center">
            <div class="flex-shrink-0">
                <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div class="ml-2">
                <p class="text-sm text-yellow-700">
                Dane mogą być niepełne.
                {{ ' ' }}
                <span class="select-none font-medium text-yellow-700 underline hover:text-yellow-600">Aby pobrać wszystkie dane zamówienia, naciśnij przycisk Przetwórz.</span>
                </p>
            </div>
        </div>
    </div>
    </Transition>
        
    <div class="min-w-0 flex-1 xl:col-span-6 pt-5">
        <div class="flex items-center justify-between">
            <div class="select-none">
                <span v-if="!blLoaded && !bolLoaded && !wayfairLoaded && !hoodLoaded && !apiloLoaded && !ottoLoaded" class="text-sm text-gray-500">-----</span>
                <span v-if="blLoaded" class="uppercase text-sm font-semibold text-gray-600">Z Baselinker <span class="text-indigo-600">({{baselinkerBufferOrders.length}} szt.)</span></span>
                <span v-if="apiloLoaded" class="uppercase text-sm font-semibold text-gray-600">Z Apilo <span class="text-indigo-600">({{apiloBufferOrders.length}} szt.)</span></span>
                <span v-if="bolLoaded" class="uppercase text-sm font-semibold text-gray-600">Z Bol.com <span class="text-indigo-600">({{bolBufferOrders.length}} szt.)</span></span>
                <span v-if="wayfairLoaded" class="uppercase text-sm font-semibold text-gray-600">Z Wayfair.com <span class="text-indigo-600">({{wayfairBufferOrders.length}} szt.)</span></span>
                <span v-if="hoodLoaded" class="uppercase text-sm font-semibold text-gray-600">Z Hood.de <span class="text-indigo-600">({{hoodBufferOrders.length}} szt.)</span></span>
                <span v-if="ottoLoaded" class="uppercase text-sm font-semibold text-gray-600">Z Otto.market <span class="text-indigo-600">({{ottoBufferOrders.length}} szt.)</span></span>
            </div>
            <div class="flex gap-2">
                <div class="select-none flex items-center gap-2">
                    <span class="text-xs font-semibold text-gray-500">{{bolLoaded || hoodLoaded || wayfairLoaded || apiloLoaded || ottoLoaded ? 'Data' : 'Data od'}}</span>
                    <input type="date" ref="datePickerBaselinker" class="text-xs rounded border border-gray-300 bg-white px-2.5 py-1.5 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none">
                </div>
                <Transition name="fade">
                <button type="button" @click="blLoaded ? reloadBlOrders() : '', apiloLoaded ? reloadApiloOrders() : '', bolLoaded ? reloadBolOrders() : '', wayfairLoaded ? reloadWayfairOrders() : '', hoodLoaded ? reloadHoodOrders() : '', ottoLoaded ? reloadOttoOrders() : ''" :class="{'inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm': true, 'hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none': true }">
                    Odśwież
                </button>     
                </Transition>      
                <Transition name="fade">
                <button v-if="bolTable && !exactSearcher" @click.prevent="exactSearchBol()" type="button" class="transition-all duration-300 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-100 px-2.5 py-1.5 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <SearchIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Wyszukiwarka
                </button>
                </Transition>  
            </div>
        </div>
        <div class="flex gap-2 mt-4" v-if="isMarketplace.otto">
            <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Status</label>
                <select :disabled="!ottoLoaded" v-model="pickedStatus.otto" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                    <option value="PROCESSABLE">Przetwarzalne</option>
                    <option value="SENT">Wysłane</option>
                </select>
            </div>
            <button type="button" @click="reloadOttoOrders()" :class="[!ottoLoaded ? 'opacity-30 pointer-events-none' : '', 'transition ease-in-out duration-300 inline-flex items-center justify-center rounded border border-transparent bg-blue-100 px-2.5 py-2 lg:py-1.5 text-xs font-medium text-blue-700 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2']">Zastosuj</button>
        </div>
    </div>
    <Transition name="fade">
    <div v-if="exactSearcher" class="flex mt-3">
        <div>
            <label for="orderId" class="block text-sm font-medium leading-6 text-gray-900">ID zamówienia</label>
            <div class="flex">
                <input v-model="this.orderId" type="text" name="order" id="orderId" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="np.: 1590065413" />
                <button :disabled="this.orderId.length === 0" type="button" @click.prevent="reloadBolOrders('exact')" :class="['transition ease-in-out duration-300 ml-2 inline-flex items-center rounded border border-transparent bg-blue-100 px-2.5 py-1.5 text-xs font-medium text-blue-700', this.orderId.length === 0 ? 'opacity-50 cursor-default' : 'hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2']">
                    Szukaj
                </button>
            </div>
        </div>
    </div>
    </Transition>
    <!-- BASELINKER -->
    <div class="py-4 md:py-2 lg:py-0" v-if="blLoaded">
        <div class="mt-4 flex flex-col">
            <div class="overflow-x-auto">
                <div class="inline-block min-w-full py-2 align-middle">
                    <div class="overflow-hidden shadow border rounded-lg" v-if="!queryLoading">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="py-3.5 pl-4 pr-3 sm:pl-6 text-left text-sm font-semibold text-gray-900">ID</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Źródło</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Kraj</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Klient</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">E-mail</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Telefon</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">FVAT</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Wartość</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Płatność</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Data</th>
                                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    </th>
                                </tr>
                            </thead>
                            
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="(awOrd,indx) in baselinkerBufferOrders" :key="indx">
                                    <td class="whitespace-nowrap px-3 py-4 pl-4 sm:pl-6 text-xs underline hover:text-gray-500 cursor-pointer" @click.prevent="sendToBaselinkerPage(awOrd.meta.external.urlToOrder)" v-tooltip="`Baselinker: ${awOrd.meta.external.ids.baselinkerOrderId} - Sklep: ${awOrd.meta.external.ids.shopOrderId.length === 0 ? 'Brak' : awOrd.meta.external.ids.shopOrderId} - Marketplace: ${awOrd.meta.external.ids.externalOrderId.length === 0 ? 'Brak' : awOrd.meta.external.ids.externalOrderId} - Klient/Login: ${awOrd.meta.external.ids.userLogin.length === 0 ? 'Brak' : awOrd.meta.external.ids.userLogin} - Zobacz stronę zamówienia.`">
                                        {{awOrd.meta.external.ids.baselinkerOrderId}}
                                    </td>
                                    <td class="whitespace-nowrap py-4 px-3 text-xs">
                                        {{awOrd.meta.external.source.name.toUpperCase()}}
                                    </td>
                                    <td class="whitespace-nowrap py-4 px-3 text-xs">
                                        <div class="flex items-center">
                                            <img v-if="awOrd.countryFlagURL.length>0" :src="awOrd.countryFlagURL" v-tooltip="`${awOrd.delivery.countryCode}`" class="h-4 w-6 border border-gray-300">
                                            <span v-if="awOrd.countryFlagURL.length === 0 && awOrd.delivery.countryCode.length === 0">--</span>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap py-4 px-3 text-xs">
                                        <div class="flex flex-col font-medium" v-tooltip="`Nazwa: ${awOrd.delivery.fullname.length > 0 ? awOrd.delivery.fullname : awOrd.invoice.fullname.length > 0 ? awOrd.invoice.fullname : awOrd.invoice.company.length > 0 ? awOrd.invoice.company : 'Brak'} - Adres: ${awOrd.delivery.address.length === 0 ? 'Brak' : awOrd.delivery.address} - Miasto: ${awOrd.delivery.city.length === 0 ? 'Brak' : awOrd.delivery.city} - Kod pocztowy: ${awOrd.delivery.postCode.length === 0 ? 'Brak' : awOrd.delivery.postCode.length === 0 ? 'Brak' : awOrd.delivery.postCode} - Kod kraju: ${awOrd.delivery.countryCode.length === 0 ? 'Brak' : awOrd.delivery.countryCode}`">
                                            <span>{{awOrd.delivery.fullname.length > 0 ? awOrd.delivery.fullname.length  > 20 ? awOrd.delivery.fullname.substring(0,20) + '..' : awOrd.delivery.fullname  : awOrd.invoice.fullname.length > 0 ? awOrd.invoice.fullname.length > 20 ? awOrd.invoice.fullname.substring(0,20) + '..' : awOrd.invoice.fullname   : awOrd.invoice.company.length > 0 ? awOrd.invoice.company.length > 20 ? awOrd.invoice.company.substring(0,20) + ".." : awOrd.invoice.company : "--" }}</span>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap py-4 px-3 text-xs">
                                        <div @click.prevent="sendEmail(awOrd.contact.email)" class="flex items-center text-xs hover:text-gray-500 cursor-pointer" v-tooltip="`${awOrd.contact.email.length > 0 ? awOrd.contact.email + ' - Kliknij, aby napisać email.' : 'Brak e-mail'}`">
                                            {{awOrd.contact.email.length > 0 ? awOrd.contact.email.length > 20 ? awOrd.contact.email.substring(0,10)+("..") : awOrd.contact.email  : "--"}}
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap py-4 px-3 text-xs" v-tooltip="`${awOrd.contact.phone.length > 0 ? awOrd.contact.phone + ' - Kliknij, aby zadzwonić.' : 'Brak numeru.'}`">
                                        <div class="flex items-start text-xs hover:text-gray-500 cursor-pointer" @click.prevent="call(awOrd.contact.phone)">
                                            {{awOrd.contact.phone.length > 0 ? awOrd.contact.phone : "--"}}
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap py-4 px-3 text-xs" v-tooltip="`Osoba: ${awOrd.invoice.fullname.length === 0 ? 'Brak' : awOrd.invoice.fullname} - Firma: ${awOrd.invoice.company.length === 0 ? 'Brak' : awOrd.invoice.company} - Identyfikator podatkowy: ${awOrd.invoice.taxId.length === 0 ? 'Brak' : awOrd.invoice.taxId} - Adres: ${awOrd.invoice.address.length === 0 ? 'Brak' : awOrd.invoice.address} - Miasto: ${awOrd.invoice.city.length === 0 ? 'Brak' : awOrd.invoice.city} - Kod pocztowy: ${awOrd.invoice.postCode.length === 0 ? 'Brak' : awOrd.invoice.postCode} - Kod kraju: ${awOrd.invoice.countryCode.length === 0 ? 'Brak' : awOrd.invoice.countryCode}`">
                                        {{awOrd.invoice.needed ? "Tak" : "Wer."}}
                                    </td>
                                    <td class="whitespace-nowrap py-4 px-3 text-xs" v-tooltip="`Kwota brutto: ${totalPrice(awOrd.positions,awOrd.currency,awOrd.delivery.price)} (w tym przesyłka ${awOrd.delivery.method}: ${awOrd.delivery.price} ${awOrd.currency}) - Ilość: ${amountOfItems(awOrd.positions)}`">
                                            {{totalPrice(awOrd.positions,awOrd.currency,awOrd.delivery.price)}}
                                    </td>
                                    <td class="whitespace-nowrap py-4 px-3 text-xs" v-tooltip="`Metoda płatności: ${awOrd.payment.method.length === 0 ? 'Brak' : awOrd.payment.method} - Pobranie: ${awOrd.payment.cashOnDelivery ? 'Tak' : 'Nie'} - Opłacono: ${awOrd.payment.done} z ${totalPrice(awOrd.positions,awOrd.currency,awOrd.delivery.price)}`">
                                            <span class="font-medium">{{awOrd.payment.method.length === 0 ? "--" : awOrd.payment.method}}</span>
                                            <span class="ml-1 text-xs text-green-500" v-if="awOrd.payment.done > 0 && awOrd.payment.cashOnDelivery === false">(Opł.)</span>
                                            <span class="ml-1 text-xs text-yellow-600" v-if="awOrd.payment.cashOnDelivery === true">(Pobr.)</span>
                                    </td>
                                    <td class="whitespace-nowrap py-4 px-3 text-xs" v-tooltip="`Złożono zamówienie: ${orderPlacedDateFromUNIX(awOrd.meta.external.dates.add_TimestampUNIX)} - Data potwierdzenia: ${orderPlacedDateFromUNIX(awOrd.meta.external.dates.confirmation_TimestampUNIX)} `">
                                            {{orderPlacedDateFromUNIX(awOrd.meta.external.dates.add_TimestampUNIX)}}
                                    </td>
                                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 select-none">
                                        <router-link :to="`/dashboard/orders/add?origin=baselinker&id=${awOrd.meta.external.ids.baselinkerOrderId}`">
                                            <button type="button" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Przetwórz</button>
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <EmptyState class="mt-2 mb-2" v-if="baselinkerBufferOrders.length === 0"></EmptyState>
                    <div class="flex mt-2 items-center justify-center w-full" v-if="baselinkerMoreToLoad">
                        <button @click.prevent="continueBaselinkerPagination" type="button" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Załaduj więcej</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- APILO -->
    <div class="py-4 md:py-2 lg:py-2 flow-root" v-if="apiloLoaded">
        <div class="overflow-x-auto">
            <div class="inline-block min-w-full py-2 align-middle">
                <div class="overflow-hidden shadow border border-gray-200 sm:rounded-lg" v-if="!queryLoading">
                    <table class="min-w-full divide-y divide-gray-300">
                    <thead class="bg-gray-50">
                        <tr>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">ID</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Źródło</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Kraj</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Klient</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">E-mail</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Telefon</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">FVAT</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Wartość</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Płatność</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Data</th>
                            <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <span class="sr-only">Edit</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                        <tr v-for="(awOrd,indx) in apiloBufferOrders" :key="indx" :class="[isDoneTrackerDoc !== null && isDoneTrackerDoc[`${awOrd.meta.external.ids.apiloOrderId}`] !== undefined ? 'opacity-30 pointer-events-none' : '']">
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-600 cursor-pointer sm:pl-6 underline" v-tooltip="`Apilo: ${awOrd.meta.external.ids.apiloOrderId} - Sklep: ${awOrd.meta.external.ids.shopOrderId.length === 0 ? 'Brak' : awOrd.meta.external.ids.shopOrderId} - Marketplace: ${awOrd.meta.external.ids.externalOrderId.length === 0 ? 'Brak' : awOrd.meta.external.ids.externalOrderId} - Klient/Login: ${awOrd.meta.external.ids.userLogin.length === 0 ? 'Brak' : awOrd.meta.external.ids.userLogin} - Zobacz stronę zamówienia.`">
                                {{awOrd.meta.external.ids.apiloOrderId}}
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-600 font-semibold ">
                                {{awOrd.meta.external.source.name.length > 0 ? awOrd.meta.external.source.name.toUpperCase() : '--'}}
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                                <div class="flex items-center">
                                    <img v-if="awOrd.countryFlagURL.length>0" :src="awOrd.countryFlagURL" v-tooltip="`${awOrd.delivery.countryCode}`" class="h-4 w-6 border border-gray-300">
                                    <span v-if="awOrd.countryFlagURL.length === 0 && awOrd.delivery.countryCode.length === 0">--</span>
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-600 font-semibold">
                                <div class="flex flex-col font-medium w-20 cursor-pointer" v-tooltip="`Nazwa: ${awOrd.delivery.fullname.length > 0 ? awOrd.delivery.fullname : awOrd.invoice.fullname.length > 0 ? awOrd.invoice.fullname : awOrd.invoice.company.length > 0 ? awOrd.invoice.company : 'Brak'} - Adres: ${awOrd.delivery.address.length === 0 ? 'Brak' : awOrd.delivery.address} - Miasto: ${awOrd.delivery.city.length === 0 ? 'Brak' : awOrd.delivery.city} - Kod pocztowy: ${awOrd.delivery.postCode.length === 0 ? 'Brak' : awOrd.delivery.postCode.length === 0 ? 'Brak' : awOrd.delivery.postCode} - Kod kraju: ${awOrd.delivery.countryCode.length === 0 ? 'Brak' : awOrd.delivery.countryCode}`">
                                    <span class="truncate">{{awOrd.delivery.fullname.length > 0 ? awOrd.delivery.fullname : awOrd.invoice.fullname.length > 0 ? awOrd.invoice.fullname : awOrd.invoice.company.length > 0 ? awOrd.invoice.company : "--" }}</span>
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-600">
                                <div @click.prevent="sendEmail(awOrd.contact.email)" class="flex items-center text-xs hover:text-gray-500 cursor-pointer w-20" v-tooltip="`${awOrd.contact.email.length > 0 ? awOrd.contact.email + ' - Kliknij, aby napisać email.' : 'Brak e-mail'}`">
                                    <span class="truncate">{{awOrd.contact.email.length > 0 ? awOrd.contact.email.length > 20 ? awOrd.contact.email.substring(0,10)+("..") : awOrd.contact.email  : "--"}}</span>
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-600">
                                <div class="flex items-start text-xs hover:text-gray-500 cursor-pointer" v-tooltip="`${awOrd.contact.phone != null ? awOrd.contact.phone.length > 0 ?  awOrd.contact.phone + ' - Kliknij, aby zadzwonić.' : 'Brak numeru.' : ''}`" @click.prevent="call(awOrd.contact.phone)">
                                    {{awOrd.contact.phone != null ? awOrd.contact.phone.length > 0 ? awOrd.contact.phone : "---" : '---'}}
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-600">
                                <div class="flex items-start cursor-pointer" v-tooltip="`Osoba: ${awOrd.invoice.fullname.length === 0 ? 'Brak' : awOrd.invoice.fullname} - Firma: ${awOrd.invoice.company.length === 0 ? 'Brak' : awOrd.invoice.company} - Identyfikator podatkowy: ${awOrd.invoice.taxId.length === 0 ? 'Brak' : awOrd.invoice.taxId} - Adres: ${awOrd.invoice.address.length === 0 ? 'Brak' : awOrd.invoice.address} - Miasto: ${awOrd.invoice.city.length === 0 ? 'Brak' : awOrd.invoice.city} - Kod pocztowy: ${awOrd.invoice.postCode.length === 0 ? 'Brak' : awOrd.invoice.postCode} - Kod kraju: ${awOrd.invoice.countryCode.length === 0 ? 'Brak' : awOrd.invoice.countryCode}`">
                                    <span>{{awOrd.invoice.needed ? "Tak" : "Wer."}}</span>
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-600">
                                <div class="flex items-start cursor-pointer" v-tooltip="`Kwota brutto: ${totalPrice(awOrd.positions,awOrd.currency,awOrd.delivery.price)} (w tym przesyłka ${awOrd.delivery.method}: ${awOrd.delivery.price} ${awOrd.currency}) - Ilość: ${amountOfItems(awOrd.positions)}`">
                                    {{totalPrice(awOrd.positions,awOrd.currency,awOrd.delivery.price)}}
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-600">
                                <div class="flex items-start cursor-pointer" v-tooltip="`Metoda płatności: ${awOrd.payment.method.length === 0 ? 'Brak' : awOrd.payment.method} - Pobranie: ${awOrd.payment.cashOnDelivery ? 'Tak' : 'Nie'} - Opłacono: ${awOrd.payment.done} z ${totalPrice(awOrd.positions,awOrd.currency,awOrd.delivery.price)}`">
                                    <span class="font-medium">{{awOrd.payment.method.length === 0 ? "--" : awOrd.payment.method}}</span>
                                    <span class="ml-1 text-xs text-green-500" v-if="awOrd.payment.done > 0 && awOrd.payment.cashOnDelivery === false">(Opł.)</span>
                                    <span class="ml-1 text-xs text-yellow-600" v-if="awOrd.payment.cashOnDelivery === true">(Pobr.)</span>
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-600">
                                <div class="flex items-start cursor-pointer" v-tooltip="`Złożono zamówienie: ${orderPlacedDateFromUNIX(awOrd.meta.external.dates.add_TimestampUNIX)} - Data potwierdzenia: ${orderPlacedDateFromUNIX(awOrd.meta.external.dates.confirmation_TimestampUNIX)}`">
                                    {{orderPlacedDateFromUNIX(awOrd.meta.external.dates.add_TimestampUNIX)}}
                                </div>
                            </td>
                            <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <router-link :to="`/dashboard/orders/add?origin=apilo&id=${awOrd.meta.external.ids.apiloOrderId}&dateOrigin=${moment.unix(awOrd.meta.external.dates.add_TimestampUNIX).format('YYYY-MM-DD')}`">
                                <button type="button" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <div class="flex items-center"> <span class="mr-2" v-if="isDoneTrackerDoc !== null && isDoneTrackerDoc[`${awOrd.meta.external.ids.apiloOrderId}`] !== undefined"><CheckIcon class="h-5 w-5 text-green-700"></CheckIcon> </span><span>Przetwórz</span></div>
                                </button>
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                <EmptyState class="mt-2 mb-2" v-if="apiloBufferOrders.length === 0" />
                <div class="flex mt-2 items-center justify-center w-full" v-if="apiloMoreToLoad">
                    <button @click.prevent="continueApiloPagination" type="button" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Załaduj więcej</button>
                </div>
            </div>
        </div>
    </div>
    <!-- OTTO -->
    <div class="py-4 md:py-2 lg:py-2 flow-root" v-if="ottoLoaded">
        <div class="overflow-x-auto">
            <div class="inline-block min-w-full py-2 align-middle">
                <div class="overflow-hidden shadow border border-gray-200 sm:rounded-lg" v-if="!queryLoading">
                    <table class="min-w-full divide-y divide-gray-300">
                    <thead class="bg-gray-50">
                        <tr>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">ID</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Źródło</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Kraj</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Klient</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">E-mail</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Telefon</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">FVAT</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Wartość</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Płatność</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Data</th>
                            <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <span class="sr-only">Edit</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                        <tr v-for="(awOrd,indx) in ottoBufferOrders" :key="indx" :class="[isDoneTrackerDoc !== null && isDoneTrackerDoc[`${awOrd.meta.external.ids.ottoOrderId}`] !== undefined ? 'opacity-30 pointer-events-none' : '']">
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-600 cursor-pointer sm:pl-6 underline" v-tooltip="`Otto: ${awOrd.meta.external.ids.ottoOrderId} - Sklep: ${awOrd.meta.external.ids.shopOrderId.length === 0 ? 'Brak' : awOrd.meta.external.ids.shopOrderId} - Marketplace: ${awOrd.meta.external.ids.externalOrderId.length === 0 ? 'Brak' : awOrd.meta.external.ids.externalOrderId} - Klient/Login: ${awOrd.meta.external.ids.userLogin.length === 0 ? 'Brak' : awOrd.meta.external.ids.userLogin} - Zobacz stronę zamówienia.`">
                                {{awOrd.meta.external.ids.ottoOrderId}}
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-600 font-semibold ">
                                {{awOrd.meta.external.source.name.toUpperCase()}}
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                                <div class="flex items-center">
                                    <img v-if="awOrd.countryFlagURL.length>0" :src="awOrd.countryFlagURL" v-tooltip="`${awOrd.delivery.countryCode}`" class="h-4 w-6 border border-gray-300">
                                    <span v-if="awOrd.countryFlagURL.length === 0 && awOrd.delivery.countryCode.length === 0">--</span>
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-600 font-semibold">
                                <div class="flex flex-col font-medium w-20 cursor-pointer" v-tooltip="`Nazwa: ${awOrd.delivery.fullname.length > 0 ? awOrd.delivery.fullname : awOrd.invoice.fullname.length > 0 ? awOrd.invoice.fullname : awOrd.invoice.company.length > 0 ? awOrd.invoice.company : 'Brak'} - Adres: ${awOrd.delivery.address.length === 0 ? 'Brak' : awOrd.delivery.address} - Miasto: ${awOrd.delivery.city.length === 0 ? 'Brak' : awOrd.delivery.city} - Kod pocztowy: ${awOrd.delivery.postCode.length === 0 ? 'Brak' : awOrd.delivery.postCode.length === 0 ? 'Brak' : awOrd.delivery.postCode} - Kod kraju: ${awOrd.delivery.countryCode.length === 0 ? 'Brak' : awOrd.delivery.countryCode}`">
                                    <span class="truncate">{{awOrd.delivery.fullname.length > 0 ? awOrd.delivery.fullname : awOrd.invoice.fullname.length > 0 ? awOrd.invoice.fullname : awOrd.invoice.company.length > 0 ? awOrd.invoice.company : "--" }}</span>
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-600">
                                <div @click.prevent="sendEmail(awOrd.contact.email)" class="flex items-center text-xs hover:text-gray-500 cursor-pointer w-20" v-tooltip="`${awOrd.contact.email.length > 0 ? awOrd.contact.email + ' - Kliknij, aby napisać email.' : 'Brak e-mail'}`">
                                    <span class="truncate">{{awOrd.contact.email.length > 0 ? awOrd.contact.email.length > 20 ? awOrd.contact.email.substring(0,10)+("..") : awOrd.contact.email  : "--"}}</span>
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-600">
                                <div class="flex items-start text-xs hover:text-gray-500 cursor-pointer" v-tooltip="`${awOrd.contact.phone != null ? awOrd.contact.phone.length > 0 ?  awOrd.contact.phone + ' - Kliknij, aby zadzwonić.' : 'Brak numeru.' : ''}`" @click.prevent="call(awOrd.contact.phone)">
                                    {{awOrd.contact.phone != null ? awOrd.contact.phone.length > 0 ? awOrd.contact.phone : "---" : '---'}}
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-600">
                                <div class="flex items-start cursor-pointer" v-tooltip="`Osoba: ${awOrd.invoice.fullname.length === 0 ? 'Brak' : awOrd.invoice.fullname} - Firma: ${awOrd.invoice.company.length === 0 ? 'Brak' : awOrd.invoice.company} - Identyfikator podatkowy: ${awOrd.invoice.taxId.length === 0 ? 'Brak' : awOrd.invoice.taxId} - Adres: ${awOrd.invoice.address.length === 0 ? 'Brak' : awOrd.invoice.address} - Miasto: ${awOrd.invoice.city.length === 0 ? 'Brak' : awOrd.invoice.city} - Kod pocztowy: ${awOrd.invoice.postCode.length === 0 ? 'Brak' : awOrd.invoice.postCode} - Kod kraju: ${awOrd.invoice.countryCode.length === 0 ? 'Brak' : awOrd.invoice.countryCode}`">
                                    <span>{{awOrd.invoice.needed ? "Tak" : "Wer."}}</span>
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-600">
                                <div class="flex items-start cursor-pointer" v-tooltip="`Kwota brutto: ${totalPrice(awOrd.positions,awOrd.currency,awOrd.delivery.price)} (w tym przesyłka ${awOrd.delivery.method}: ${awOrd.delivery.price} ${awOrd.currency}) - Ilość: ${amountOfItems(awOrd.positions)}`">
                                    {{totalPrice(awOrd.positions,awOrd.currency,awOrd.delivery.price)}}
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-600">
                                <div class="flex items-start cursor-pointer" v-tooltip="`Metoda płatności: ${awOrd.payment.method.length === 0 ? 'Brak' : awOrd.payment.method} - Pobranie: ${awOrd.payment.cashOnDelivery ? 'Tak' : 'Nie'} - Opłacono: ${awOrd.payment.done} z ${totalPrice(awOrd.positions,awOrd.currency,awOrd.delivery.price)}`">
                                    <span class="font-medium">{{awOrd.payment.method.length === 0 ? "--" : awOrd.payment.method}}</span>
                                    <span class="ml-1 text-xs text-green-500" v-if="awOrd.payment.done > 0 && awOrd.payment.cashOnDelivery === false">(Opł.)</span>
                                    <span class="ml-1 text-xs text-yellow-600" v-if="awOrd.payment.cashOnDelivery === true">(Pobr.)</span>
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-600">
                                <div class="flex items-start cursor-pointer" v-tooltip="`Złożono zamówienie: ${orderPlacedDateFromUNIX(awOrd.meta.external.dates.add_TimestampUNIX)} - Data potwierdzenia: ${orderPlacedDateFromUNIX(awOrd.meta.external.dates.confirmation_TimestampUNIX)}`">
                                    {{orderPlacedDateFromUNIX(awOrd.meta.external.dates.add_TimestampUNIX)}}
                                </div>
                            </td>
                            <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <router-link :to="`/dashboard/orders/add?origin=otto&id=${awOrd.meta.external.ids.ottoOrderId}&dateOrigin=${moment.unix(awOrd.meta.external.dates.add_TimestampUNIX).format('YYYY-MM-DD')}&data=${encodeURIComponent(getObjString(awOrd))}`">
                                <button type="button" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <div class="flex items-center"> <span class="mr-2" v-if="isDoneTrackerDoc !== null && isDoneTrackerDoc[`${awOrd.meta.external.ids.ottoOrderId}`] !== undefined"><CheckIcon class="h-5 w-5 text-green-700"></CheckIcon> </span><span>Przetwórz</span></div>
                                </button>
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                <EmptyState class="mt-2 mb-2" v-if="ottoBufferOrders.length === 0" />
                <div class="flex mt-2 items-center justify-center w-full" v-if="ottoMoreToLoad">
                    <button @click.prevent="continueOttoPagination" type="button" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Załaduj więcej</button>
                </div>
            </div>
        </div>
    </div>
    <!-- BOL -->
    <div class="py-4 md:py-2 lg:py-2 flow-root" v-if="bolLoaded">
        <div class="overflow-x-auto">
            <div class="inline-block min-w-full py-2 align-middle">
                <div class="overflow-hidden shadow border border-gray-200 sm:rounded-lg" v-if="!queryLoading">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">ID</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Źródło</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Kraj</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Klient</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">E-mail</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Telefon</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">FVAT</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Wartość</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Płatność</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Data</th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    
                                </th>
                            </tr>
                        </thead>

                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="(awOrd,indx) in bolBufferOrders" :key="indx" :class="[isDoneTrackerDoc !== null && isDoneTrackerDoc[`${awOrd.meta.external.ids.bolOrderId}`] !== undefined ? 'opacity-20 pointer-events-none' : '']">
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6 underline hover:text-gray-500 cursor-pointer" @click.prevent="sendToBolPage(awOrd.meta.external.urlToOrder)" v-tooltip="`Bol: ${awOrd.meta.external.ids.bolOrderId} - Sklep: ${awOrd.meta.external.ids.shopOrderId.length === 0 ? 'Brak' : awOrd.meta.external.ids.shopOrderId} - Marketplace: ${awOrd.meta.external.ids.externalOrderId.length === 0 ? 'Brak' : awOrd.meta.external.ids.externalOrderId} - Klient/Login: ${awOrd.meta.external.ids.userLogin.length === 0 ? 'Brak' : awOrd.meta.external.ids.userLogin} - Zobacz stronę zamówienia.`">
                                    {{awOrd.meta.external.ids.bolOrderId}}
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                    {{awOrd.meta.external.source.name.toUpperCase()}}
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                    <div class="flex items-center">
                                        <img v-if="awOrd.countryFlagURL.length>0" :src="awOrd.countryFlagURL" v-tooltip="`${awOrd.delivery.countryCode}`" class="h-4 w-6 border border-gray-300">
                                        <span v-if="awOrd.countryFlagURL.length === 0 && awOrd.delivery.countryCode.length === 0">--</span>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                    <div class="flex flex-col font-medium w-20 cursor-pointer" v-tooltip="`Nazwa: ${awOrd.delivery.fullname.length > 0 ? awOrd.delivery.fullname : awOrd.invoice.fullname.length > 0 ? awOrd.invoice.fullname : awOrd.invoice.company.length > 0 ? awOrd.invoice.company : 'Brak'} - Adres: ${awOrd.delivery.address.length === 0 ? 'Brak' : awOrd.delivery.address} - Miasto: ${awOrd.delivery.city.length === 0 ? 'Brak' : awOrd.delivery.city} - Kod pocztowy: ${awOrd.delivery.postCode.length === 0 ? 'Brak' : awOrd.delivery.postCode.length === 0 ? 'Brak' : awOrd.delivery.postCode} - Kod kraju: ${awOrd.delivery.countryCode.length === 0 ? 'Brak' : awOrd.delivery.countryCode}`">
                                        <span class="truncate">{{awOrd.delivery.fullname.length > 0 ? awOrd.delivery.fullname.length  > 20 ? awOrd.delivery.fullname.substring(0,20) + '..' : awOrd.delivery.fullname  : awOrd.invoice.fullname.length > 0 ? awOrd.invoice.fullname.length > 20 ? awOrd.invoice.fullname.substring(0,20) + '..' : awOrd.invoice.fullname   : awOrd.invoice.company.length > 0 ? awOrd.invoice.company.length > 20 ? awOrd.invoice.company.substring(0,20) + ".." : awOrd.invoice.company : "--" }}</span>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                    <div @click.prevent="sendEmail(awOrd.contact.email)" class="w-20 flex items-center text-xs hover:text-gray-500 cursor-pointer" v-tooltip="`${awOrd.contact.email.length > 0 ? awOrd.contact.email + ' - Kliknij, aby napisać email.' : 'Brak e-mail'}`">
                                        <span class="truncate">{{awOrd.contact.email.length > 0 ? awOrd.contact.email.length > 20 ? awOrd.contact.email.substring(0,20)+("..") : awOrd.contact.email  : "--"}}</span>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6" v-tooltip="`${awOrd.contact.phone !== undefined ? awOrd.contact.phone + ' - Kliknij, aby zadzwonić.' : 'Brak numeru.'}`">
                                    <div class="flex items-start text-xs hover:text-gray-500 cursor-pointer" @click.prevent="call(awOrd.contact.phone)">
                                        {{awOrd.contact.phone !== undefined ? awOrd.contact.phone : "--"}}
                                    </div>
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6" v-tooltip="`Osoba: ${awOrd.invoice.fullname.length === 0 ? 'Brak' : awOrd.invoice.fullname} - Firma: ${awOrd.invoice.company.length === 0 ? 'Brak' : awOrd.invoice.company} - Identyfikator podatkowy: ${awOrd.invoice.taxId.length === 0 ? 'Brak' : awOrd.invoice.taxId} - Adres: ${awOrd.invoice.address.length === 0 ? 'Brak' : awOrd.invoice.address} - Miasto: ${awOrd.invoice.city.length === 0 ? 'Brak' : awOrd.invoice.city} - Kod pocztowy: ${awOrd.invoice.postCode.length === 0 ? 'Brak' : awOrd.invoice.postCode} - Kod kraju: ${awOrd.invoice.countryCode.length === 0 ? 'Brak' : awOrd.invoice.countryCode}`">
                                    {{awOrd.invoice.needed ? "Tak" : "Wer."}}
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6" v-tooltip="`Kwota brutto: ${totalPrice(awOrd.positions,awOrd.currency,awOrd.delivery.price)} (w tym przesyłka ${awOrd.delivery.method}: ${awOrd.delivery.price} ${awOrd.currency}) - Ilość: ${amountOfItems(awOrd.positions)}`">
                                        {{totalPrice(awOrd.positions,awOrd.currency,awOrd.delivery.price)}}
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6" v-tooltip="`Metoda płatności: ${awOrd.payment.method.length === 0 ? 'Brak' : awOrd.payment.method} - Pobranie: ${awOrd.payment.cashOnDelivery ? 'Tak' : 'Nie'} - Opłacono: ${awOrd.payment.done} z ${totalPrice(awOrd.positions,awOrd.currency,awOrd.delivery.price)}`">
                                        <span class="font-medium">{{awOrd.payment.method.length === 0 ? "--" : awOrd.payment.method}}</span>
                                        <span class="ml-1 text-xs text-green-500" v-if="awOrd.payment.done > 0 && awOrd.payment.cashOnDelivery === false">(Opł.)</span>
                                        <span class="ml-1 text-xs text-yellow-600" v-if="awOrd.payment.cashOnDelivery === true">(Pobr.)</span>
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6" v-tooltip="`Złożono zamówienie: ${orderPlacedDateFromUNIX(awOrd.meta.external.dates.add_TimestampUNIX)} - Data potwierdzenia: ${orderPlacedDateFromUNIX(awOrd.meta.external.dates.confirmation_TimestampUNIX)} `">
                                        {{orderPlacedDateFromUNIX(awOrd.meta.external.dates.add_TimestampUNIX)}}
                                </td>
                                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 select-none">
                                    <router-link :to="`/dashboard/orders/add?origin=bol&id=${awOrd.meta.external.ids.bolOrderId}&dateOrigin=${moment.unix(awOrd.meta.external.dates.add_TimestampUNIX).format('YYYY-MM-DD')}&data=${encodeURIComponent(getObjString(awOrd))}`">
                                        <button type="button" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                            <div class="flex items-center"> <span class="mr-2" v-if="(isDoneTrackerDoc !== null && isDoneTrackerDoc[`${awOrd.meta.external.ids.bolOrderId}`] !== undefined) || exactIsTrue"><CheckIcon class="h-5 w-5 text-green-700"></CheckIcon> </span><span>Przetwórz</span></div>
                                        </button>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <EmptyState class="mt-2 mb-2" v-if="bolBufferOrders.length === 0"></EmptyState>
                <div class="flex mt-2 items-center justify-center w-full" v-if="bolMoreToLoad">
                    <button @click.prevent="continueBolPagination" type="button" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Załaduj więcej</button>
                </div>
            </div>
        </div>
    </div>
    <!-- WAYFAIR -->
    <div class="py-4 md:py-2 lg:py-2" v-if="wayfairLoaded">
        <div class="overflow-x-auto">
            <div class="inline-block min-w-full py-2 align-middle">
                <div class="overflow-hidden shadow border border-gray-200 sm:rounded-lg" v-if="!queryLoading">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">ID</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Źródło</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Kraj</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Klient</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">E-mail</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Telefon</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">FVAT</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Wartość</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Płatność</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Data</th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                </th>
                            </tr>
                        </thead>

                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="(awOrd,indx) in wayfairBufferOrders" :key="indx">
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6 underline hover:text-gray-500 cursor-pointer" @click.prevent="sendToWayfairPage(awOrd.meta.external.urlToOrder)" v-tooltip="`Bol: ${awOrd.meta.external.ids.wayfairOrderId} - Sklep: ${awOrd.meta.external.ids.shopOrderId.length === 0 ? 'Brak' : awOrd.meta.external.ids.shopOrderId} - Marketplace: ${awOrd.meta.external.ids.externalOrderId.length === 0 ? 'Brak' : awOrd.meta.external.ids.externalOrderId} - Klient/Login: ${awOrd.meta.external.ids.userLogin.length === 0 ? 'Brak' : awOrd.meta.external.ids.userLogin} - Zobacz stronę zamówienia.`">
                                    {{awOrd.meta.external.ids.wayfairOrderId}}
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                    {{awOrd.meta.external.source.name.toUpperCase()}}
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                    <div class="flex items-center">
                                        <img v-if="awOrd.countryFlagURL.length>0" :src="awOrd.countryFlagURL" v-tooltip="`${awOrd.delivery.countryCode}`" class="h-4 w-6 border border-gray-300">
                                        <span v-if="awOrd.countryFlagURL.length === 0 && awOrd.delivery.countryCode.length === 0">--</span>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                    <div class="flex flex-col font-medium w-20" v-tooltip="`Nazwa: ${awOrd.delivery.fullname.length > 0 ? awOrd.delivery.fullname : awOrd.invoice.fullname.length > 0 ? awOrd.invoice.fullname : awOrd.invoice.company.length > 0 ? awOrd.invoice.company : 'Brak'} - Adres: ${awOrd.delivery.address.length === 0 ? 'Brak' : awOrd.delivery.address} - Miasto: ${awOrd.delivery.city.length === 0 ? 'Brak' : awOrd.delivery.city} - Kod pocztowy: ${awOrd.delivery.postCode.length === 0 ? 'Brak' : awOrd.delivery.postCode.length === 0 ? 'Brak' : awOrd.delivery.postCode} - Kod kraju: ${awOrd.delivery.countryCode.length === 0 ? 'Brak' : awOrd.delivery.countryCode}`">
                                        <span class="truncate">{{awOrd.delivery.fullname.length > 0 ? awOrd.delivery.fullname.length  > 20 ? awOrd.delivery.fullname.substring(0,20) + '..' : awOrd.delivery.fullname  : awOrd.invoice.fullname.length > 0 ? awOrd.invoice.fullname.length > 20 ? awOrd.invoice.fullname.substring(0,20) + '..' : awOrd.invoice.fullname   : awOrd.invoice.company.length > 0 ? awOrd.invoice.company.length > 20 ? awOrd.invoice.company.substring(0,20) + ".." : awOrd.invoice.company : "--" }}</span>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                    <div @click.prevent="sendEmail(awOrd.contact.email)" class="w-20 flex items-center text-xs hover:text-gray-500 cursor-pointer" v-tooltip="`${awOrd.contact.email.length > 0 ? awOrd.contact.email + ' - Kliknij, aby napisać email.' : 'Brak e-mail'}`">
                                        <span class="truncate">{{awOrd.contact.email.length > 0 ? awOrd.contact.email.length > 20 ? awOrd.contact.email.substring(0,20)+("..") : awOrd.contact.email  : "--"}}</span>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6" v-tooltip="`${awOrd.contact.phone.length > 0 ? awOrd.contact.phone + ' - Kliknij, aby zadzwonić.' : 'Brak numeru.'}`">
                                    <div class="flex items-start text-xs hover:text-gray-500 cursor-pointer" @click.prevent="call(awOrd.contact.phone)">
                                        {{awOrd.contact.phone.length > 0 ? awOrd.contact.phone : "--"}}
                                    </div>
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6" v-tooltip="`Osoba: ${awOrd.invoice.fullname.length === 0 ? 'Brak' : awOrd.invoice.fullname} - Firma: ${awOrd.invoice.company.length === 0 ? 'Brak' : awOrd.invoice.company} - Identyfikator podatkowy: ${awOrd.invoice.taxId.length === 0 ? 'Brak' : awOrd.invoice.taxId} - Adres: ${awOrd.invoice.address.length === 0 ? 'Brak' : awOrd.invoice.address} - Miasto: ${awOrd.invoice.city.length === 0 ? 'Brak' : awOrd.invoice.city} - Kod pocztowy: ${awOrd.invoice.postCode.length === 0 ? 'Brak' : awOrd.invoice.postCode} - Kod kraju: ${awOrd.invoice.countryCode.length === 0 ? 'Brak' : awOrd.invoice.countryCode}`">
                                    {{awOrd.invoice.needed ? "Tak" : "Wer."}}
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6" v-tooltip="`Kwota brutto: ${totalPrice(awOrd.positions,awOrd.currency,awOrd.delivery.price)} (w tym przesyłka ${awOrd.delivery.method}: ${awOrd.delivery.price} ${awOrd.currency}) - Ilość: ${amountOfItems(awOrd.positions)}`">
                                        {{totalPrice(awOrd.positions,awOrd.currency,awOrd.delivery.price)}}
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6" v-tooltip="`Metoda płatności: ${awOrd.payment.method.length === 0 ? 'Brak' : awOrd.payment.method} - Pobranie: ${awOrd.payment.cashOnDelivery ? 'Tak' : 'Nie'} - Opłacono: ${awOrd.payment.done} z ${totalPrice(awOrd.positions,awOrd.currency,awOrd.delivery.price)}`">
                                        <span class="font-medium">{{awOrd.payment.method.length === 0 ? "--" : awOrd.payment.method}}</span>
                                        <span class="ml-1 text-xs text-green-500" v-if="awOrd.payment.done > 0 && awOrd.payment.cashOnDelivery === false">(Opł.)</span>
                                        <span class="ml-1 text-xs text-yellow-600" v-if="awOrd.payment.cashOnDelivery === true">(Pobr.)</span>
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6" v-tooltip="`Złożono zamówienie: ${orderPlacedDateFromUNIX(awOrd.meta.external.dates.add_TimestampUNIX)} - Data potwierdzenia: ${orderPlacedDateFromUNIX(awOrd.meta.external.dates.confirmation_TimestampUNIX)} `">
                                        {{orderPlacedDateFromUNIX(awOrd.meta.external.dates.add_TimestampUNIX)}}
                                </td>
                                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 select-none">
                                    <router-link :to="`/dashboard/orders/add?origin=wayfair&id=${awOrd.meta.external.ids.wayfairOrderId}&dateOrigin=${moment.unix(awOrd.meta.external.dates.add_TimestampUNIX).format('YYYY-MM-DD')}&data=${encodeURIComponent(getObjString(awOrd))}`">
                                        <button type="button" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                            <div class="flex items-center"> <span class="mr-2" v-if="isDoneTrackerDoc !== null && isDoneTrackerDoc[`${awOrd.meta.external.ids.wayfairOrderId}`] !== undefined"><CheckIcon class="h-5 w-5 text-green-700"></CheckIcon> </span><span>Przetwórz</span></div>
                                        </button>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <EmptyState class="mt-2 mb-2" v-if="wayfairBufferOrders.length === 0"></EmptyState>
                <div class="flex mt-2 items-center justify-center w-full" v-if="wayfairMoreToLoad">
                    <button @click.prevent="continueWayfairPagination" type="button" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Załaduj więcej</button>
                </div>
            </div>
        </div>
    </div>
    <!-- HOOD -->
    <div class="py-4 md:py-2 lg:py-2" v-if="hoodLoaded">
        <div class="overflow-x-auto">
            <div class="inline-block min-w-full py-2 align-middle">
                <div class="overflow-hidden shadow border border-gray-200 sm:rounded-lg" v-if="!queryLoading">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">ID</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Źródło</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Kraj</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Klient</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">E-mail</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Telefon</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">FVAT</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Wartość</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Płatność</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Data</th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span class="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>

                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="(awOrd,indx) in hoodBufferOrders" :key="indx">
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6 underline hover:text-gray-500 cursor-pointer" @click.prevent="sendToBaselinkerPage(awOrd.meta.external.urlToOrder)" v-tooltip="`Hood.de: ${awOrd.meta.external.ids.hoodOrderId} - Sklep: ${awOrd.meta.external.ids.shopOrderId === null ? 'Brak' : awOrd.meta.external.ids.shopOrderId} - Marketplace: ${awOrd.meta.external.ids.externalOrderId === null ? 'Brak' : awOrd.meta.external.ids.externalOrderId} - Klient/Login: ${awOrd.meta.external.ids.userLogin === null ? 'Brak' : awOrd.meta.external.ids.userLogin} - Zobacz stronę zamówienia.`">
                                    {{awOrd.meta.external.ids.hoodOrderId}}
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                    {{awOrd.meta.external.source.name.toUpperCase()}}
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                    <div class="flex items-center">
                                        <img v-if="awOrd.countryFlagURL.length>0" :src="awOrd.countryFlagURL" v-tooltip="`${awOrd.delivery.countryCode}`" class="h-4 w-6 border border-gray-300">
                                        <span v-if="awOrd.countryFlagURL.length === 0 && awOrd.delivery.countryCode.length === 0">--</span>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                    <div class="flex flex-col font-medium w-20" v-tooltip="`Nazwa: ${awOrd.delivery.fullname.length > 0 ? awOrd.delivery.fullname : awOrd.invoice.fullname.length > 0 ? awOrd.invoice.fullname : awOrd.invoice.company.length > 0 ? awOrd.invoice.company : 'Brak'} - Adres: ${awOrd.delivery.address.length === 0 ? 'Brak' : awOrd.delivery.address} - Miasto: ${awOrd.delivery.city.length === 0 ? 'Brak' : awOrd.delivery.city} - Kod pocztowy: ${awOrd.delivery.postCode.length === 0 ? 'Brak' : awOrd.delivery.postCode.length === 0 ? 'Brak' : awOrd.delivery.postCode} - Kod kraju: ${awOrd.delivery.countryCode.length === 0 ? 'Brak' : awOrd.delivery.countryCode}`">
                                        <span class="truncate">{{awOrd.delivery.fullname.length > 0 ? awOrd.delivery.fullname.length  > 20 ? awOrd.delivery.fullname.substring(0,20) + '..' : awOrd.delivery.fullname  : awOrd.invoice.fullname.length > 0 ? awOrd.invoice.fullname.length > 20 ? awOrd.invoice.fullname.substring(0,20) + '..' : awOrd.invoice.fullname   : awOrd.invoice.company.length > 0 ? awOrd.invoice.company.length > 20 ? awOrd.invoice.company.substring(0,20) + ".." : awOrd.invoice.company : "--" }}</span>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                    <div @click.prevent="sendEmail(awOrd.contact.email)" class="w-20 flex items-center text-xs hover:text-gray-500 cursor-pointer" v-tooltip="`${awOrd.contact.email.length > 0 ? awOrd.contact.email + ' - Kliknij, aby napisać email.' : 'Brak e-mail'}`">
                                        <span class="truncate">{{awOrd.contact.email.length > 0 ? awOrd.contact.email.length > 20 ? awOrd.contact.email.substring(0,20)+("..") : awOrd.contact.email  : "--"}}</span>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6" v-tooltip="`${awOrd.contact.phone.length > 0 ? 'Brak numeru.' : awOrd.contact.phone + ' - Kliknij, aby zadzwonić.'}`">
                                    <div class="flex items-start text-xs hover:text-gray-500 cursor-pointer" @click.prevent="call(awOrd.contact.phone)">
                                        {{awOrd.contact.phone.length > 0 ? "--" : awOrd.contact.phone}}
                                    </div>
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6" v-tooltip="`Osoba: ${awOrd.invoice.fullname === undefined ? 'Brak' : awOrd.invoice.fullname} - Firma: ${awOrd.invoice.company === undefined ? 'Brak' : awOrd.invoice.company} - Identyfikator podatkowy: ${awOrd.invoice.taxId === undefined ? 'Brak' : awOrd.invoice.taxId} - Adres: ${awOrd.invoice.address === undefined ? 'Brak' : awOrd.invoice.address} - Miasto: ${awOrd.invoice.city === undefined ? 'Brak' : awOrd.invoice.city} - Kod pocztowy: ${awOrd.invoice.postCode === undefined ? 'Brak' : awOrd.invoice.postCode} - Kod kraju: ${awOrd.invoice.countryCode === undefined ? 'Brak' : awOrd.invoice.countryCode}`">
                                    {{awOrd.invoice.needed ? "Tak" : "Wer."}}
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6" v-tooltip="`Kwota brutto: ${totalPrice(awOrd.positions,awOrd.currency,awOrd.delivery.price)} (w tym przesyłka ${awOrd.delivery.method}: ${awOrd.delivery.price} ${awOrd.currency}) - Ilość: ${amountOfItems(awOrd.positions)}`">
                                        {{totalPrice(awOrd.positions,awOrd.currency,awOrd.delivery.price)}}
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6" v-tooltip="`Metoda płatności: ${Object.keys(awOrd.payment.method).length === 0 ? 'Brak' : awOrd.payment.method} - Pobranie: ${awOrd.payment.cashOnDelivery ? 'Tak' : 'Nie'} - Opłacono: ${awOrd.payment.done} z ${totalPrice(awOrd.positions,awOrd.currency,awOrd.delivery.price)}`">
                                        <span class="font-medium">{{awOrd.payment.method.length === 0 ? "--" : awOrd.payment.method}}</span>
                                        <span class="ml-1 text-xs text-green-500" v-if="awOrd.payment.done > 0 && awOrd.payment.cashOnDelivery === false">(Opł.)</span>
                                        <span class="ml-1 text-xs text-yellow-600" v-if="awOrd.payment.cashOnDelivery === true">(Pobr.)</span>
                                </td>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6" v-tooltip="`Złożono zamówienie: ${orderPlacedDateFromUNIX(awOrd.meta.external.dates.add_TimestampUNIX)} - Data potwierdzenia: ${orderPlacedDateFromUNIX(awOrd.meta.external.dates.confirmation_TimestampUNIX)} `">
                                        {{orderPlacedDateFromUNIX(awOrd.meta.external.dates.add_TimestampUNIX)}}
                                </td>
                                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 select-none">
                                    <router-link :to="`/dashboard/orders/add?origin=hood&id=${awOrd.meta.external.ids.hoodOrderId}&dateOrigin=${moment.unix(awOrd.meta.external.dates.add_TimestampUNIX).format('YYYY-MM-DD')}&data=${encodeURIComponent(getObjString(awOrd))}`">
                                        <button type="button" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                            <div class="flex items-center"> <span class="mr-2" v-if="isDoneTrackerDoc !== null && isDoneTrackerDoc[`${awOrd.meta.external.ids.hoodOrderId}`] !== undefined">
                                                <CheckIcon class="h-5 w-5 text-green-700"></CheckIcon> </span><span>Przetwórz</span></div>
                                        </button>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <EmptyState class="mt-2 mb-2" v-if="hoodBufferOrders.length === 0"></EmptyState>
                <div class="flex mt-2 items-center justify-center w-full" v-if="hoodMoreToLoad">
                    <button @click.prevent="continueHoodPagination" type="button" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Załaduj więcej</button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="!blLoaded && !bolLoaded && !wayfairLoaded && !hoodLoaded && !apiloLoaded && !ottoLoaded">
        <Loader />
    </div>
</div>
</template>

<script>
    import {db} from "@/firebase/gfbconf.js"
    import EmptyState from '@/components/EmptyState.vue';
    import { ViewGridIcon } from '@heroicons/vue/outline';
    import { HomeIcon, CheckIcon, SearchIcon, ViewGridAddIcon, ExclamationIcon } from '@heroicons/vue/solid';
    import Loader from '@/components/LoadingSpinnerBlue.vue';
    import axios from 'axios';
    import moment from 'moment';
    import CryptoJS from 'crypto-js';
    export default {
        name: "AwaitingOrders",
        data() {
            return {
                app: {
                    recurrence: false
                },
                pickedStatus: {
                    otto: "PROCESSABLE",
                },
                isDoneTrackerDoc: null,
                lastDateToTracker: null,
                isDisabled: false,
                blTable: false,
                apiloTable: false,
                bolTable: false,
                wayfairTable: false,
                hoodTable: false,
                isMarketplace: {
                    bl: false,
                    apilo: false,
                    bol: false,
                    wayfair: false,
                    hood: false,
                    otto: false,
                },
                baselinkerBufferOrders: [],
                baselinkerMoreToLoad: false,
                baselinkerLastLoaded: null,
                apiloBufferOrders: [],
                apiloMoreToLoad: false,
                apiloLastLoaded: null,
                wayfairBufferOrders: [],
                wayfairMoreToLoad: false,
                wayfairLastLoaded: null,
                bolBufferOrders: [],
                bolMoreToLoad: false,
                bolLastLoaded: null,
                hoodBufferOrders: [],
                hoodMoreToLoad: false,
                hoodLastLoaded: null,
                ottoMoreToLoad: false,
                ottoLastLoaded: null,
                ottoBufferOrders: [],
                blLoaded: false,
                bolLoaded: false,
                apiloLoaded: false,
                ottoLoaded: false,
                wayfairLoaded: false,
                hoodLoaded: false,
                queryLoading: false,
                tabs: [
                    // { name: 'Baselinker', icon: ViewGridAddIcon, current: true },
                    { name: 'Otto.market', icon: ViewGridAddIcon, current: true },
                    { name: 'Apilo', icon: ViewGridAddIcon, current: false },
                    { name: 'Bol.com', icon: ViewGridAddIcon, current: false },
                    { name: 'Wayfair.com', icon: ViewGridAddIcon, current: false },
                    { name: 'Hood.de', icon: ViewGridAddIcon, current: false },
                ],
                exactSearcher: false,
                orderId: '',
                exactIsTrue: false,
            }
        },
        components: {
            ExclamationIcon, ViewGridAddIcon, ViewGridIcon, HomeIcon,Loader,EmptyState,CheckIcon,SearchIcon
        },
        async created()
        {
            this.moment = moment;
            if(!this.$store.state.userData.permissions.admin)
            {
                if(this.$store.state.userData.permissions.orders === false)
                {
                    this.forcedClose()
                }
            }
            this.dbListener = db.collection('Configs').doc('Listener')
            .onSnapshot((querySnapshot) => {
                this.app.recurrence = querySnapshot.data().recurrence;
            });
        },
        async mounted()
        {
            // Set picker date on start
            this.setDatePickersOnStart();
            if(this.$refs.datePickerBaselinker !== undefined)
            {
                let mDate = moment(this.$refs.datePickerBaselinker.value,"YYYY-MM-DD")
                if(mDate.isValid())
                {
                    let unix = mDate.unix();
                    await this.getAwaitingOttoOrders(unix)
                }
            }
        },
        methods:
        {
            async getDoneBolOrders()
            {
                try
                {
                    const res = await db.collection('AwaitingOrderDone_bol').get()
                    for(let i=0; i<res.docs.length; i++)
                    {
                        let data = res.docs[i].data();
                        this.isDoneTrackerDoc = data
                            const orderId = Object.entries(data)
                            for(let y=0; y<orderId.length; y++)
                            {
                                if(orderId[y][0] === this.orderId)
                                {
                                    this.exactIsTrue = true;
                                }
                            }
                    }
                }
                catch (error)
                {
                    console.log(error.message);
                }
            },
            exactSearchBol()
            {
                this.exactSearcher = !this.exactSearcher;
                if(!this.exactSearcher)
                {
                    this.orderId = '';
                    this.exactIsTrue = false;
                    this.reloadBolOrders();
                }
            },
            getObjString(data)
            {
                let str = JSON.stringify(data);
                let encrypted = CryptoJS.AES.encrypt(str, 'prodData').toString();
                return encrypted;
            },
            async continueBaselinkerPagination()
            {
                if(this.baselinkerMoreToLoad)
                {
                    if(this.baselinkerLastLoaded !== null)
                    {

                        let payload = {
                            whoAmI: this.$store.state.userData.id,
                            unixTimestamp: this.baselinkerLastLoaded+1,
                            specific: false,
                        }

                        // Get new
                        const res = await axios.post(`${this.$store.state.apiLink}/getAwaitngOrdersFromBaselinker`, payload )

                        // Decide to show button
                        if(res.data.bufferOrders.length < 100)
                        {
                            this.baselinkerMoreToLoad = false;
                        }
                        else
                        {
                            this.baselinkerMoreToLoad = true;
                        }

                        // Save last UNIX timestamp
                        if(res.data.bufferOrders.length > 0)
                        {
                            this.baselinkerLastLoaded = res.data.bufferOrders[res.data.bufferOrders.length-1].meta.external.dates.add_TimestampUNIX;
                        }

                        let alreadyDownloadedFlags = [];
                        let flagsData = [];
                        
                        // Find right flag
                        for(let i=0; i< res.data.bufferOrders.length;i++)
                        {
                            let current = res.data.bufferOrders[i];
                            current.countryFlagURL = "";

                            // Check in existing orders
                            for(let y=0; y < this.baselinkerBufferOrders.length; y++)
                            {
                                if(current.delivery.countryCode === this.baselinkerBufferOrders[y].delivery.countryCode)
                                {
                                    current.countryFlagURL = this.baselinkerBufferOrders[y].countryFlagURL;
                                    break;
                                }
                            }

                            // download if flag not found
                            if(current.countryFlagURL.length === 0)
                            {
                                if(!alreadyDownloadedFlags.includes(current.delivery.countryCode))
                                {
                                    alreadyDownloadedFlags.push(current.delivery.countryCode);
                                    let flagData = {
                                        code: current.delivery.countryCode,
                                        url: await this.getFlag(this.baselinkerBufferOrders[i].delivery.countryCode)
                                    }
                                    flagsData.push(flagData)
                                    current.countryFlagURL = flagData.url;
                                }
                                else
                                {
                                    // get from already downloaded flags
                                    for(let y=0;y<flagsData.length;y++)
                                    {
                                        if(flagsData[y].code === current.delivery.countryCode)
                                        {
                                            current.countryFlagURL = flagsData[y].url;
                                            break;
                                        }
                                    }
                                }
                            }
                            this.baselinkerBufferOrders.push(current);
                        }
                    
                    }
                }
            },
            async continueOttoPagination()
            {
                if(this.ottoMoreToLoad)
                {
                    if(this.ottoLastLoaded !== null)
                    {
                        let payload = {
                            whoAmI: this.$store.state.userData.id,
                            specific: false,
                            options: {},
                        }
                        if (this.ottoLastLoaded !== undefined) {
                            payload.options.createdAfter = this.ottoLastLoaded + 1;
                            payload.options.status = this.pickedStatus.otto;
                        }

                        // Get new
                        const res = await axios.post(`${this.$store.state.herokuApiLink}/marketplace/ottomarket/${this.$store.state.herokuApiPath}/orders/get`, payload, {
                            // timeout: 20000,
                            headers: {
                                'x-api-jwt-token': this.$store.state.userData.jwt,
                                'x-api-key': process.env.VUE_APP_APIKEY,
                            },
                        })
                        
                        // Decide to show button
                        if(res.data.payload.orders.length < 100)
                        {
                            this.ottoMoreToLoad = false;
                        }
                        else
                        {
                            this.ottoMoreToLoad = true;
                        }

                        // Save last UNIX timestamp
                        if(res.data.payload.orders.length > 0)
                        {
                            this.ottoLastLoaded = res.data.payload.orders[res.data.payload.orders.length-1].meta.external.dates.add_TimestampUNIX;
                        }

                        let alreadyDownloadedFlags = [];
                        let flagsData = [];
                        
                        // Find right flag
                        for(let i=0; i< res.data.payload.orders.length;i++)
                        {
                            let current = res.data.payload.orders[i];
                            current.countryFlagURL = "";

                            // Check in existing orders
                            for(let y=0; y < this.ottoBufferOrders.length; y++)
                            {
                                if(current.delivery.countryCode === this.ottoBufferOrders[y].delivery.countryCode)
                                {
                                    current.countryFlagURL = this.ottoBufferOrders[y].countryFlagURL;
                                    break;
                                }
                            }

                            // download if flag not found
                            if(current.countryFlagURL.length === 0)
                            {
                                if(!alreadyDownloadedFlags.includes(current.delivery.countryCode))
                                {
                                    alreadyDownloadedFlags.push(current.delivery.countryCode);
                                    let flagData = {
                                        code: current.delivery.countryCode,
                                        url: await this.getFlag(this.ottoBufferOrders[i].delivery.countryCode)
                                    }
                                    flagsData.push(flagData)
                                    current.countryFlagURL = flagData.url;
                                }
                                else
                                {
                                    // get from already downloaded flags
                                    for(let y=0;y<flagsData.length;y++)
                                    {
                                        if(flagsData[y].code === current.delivery.countryCode)
                                        {
                                            current.countryFlagURL = flagsData[y].url;
                                            break;
                                        }
                                    }
                                }
                            }
                            this.ottoBufferOrders.push(current);
                        }
                    
                    }
                }
            },
            async continueApiloPagination()
            {
                if(this.apiloMoreToLoad)
                {
                    if(this.apiloLastLoaded !== null)
                    {

                        let payload = {
                            whoAmI: this.$store.state.userData.id,
                            specific: false,
                            options: {
                                createdAfter: moment.unix(this.apiloLastLoaded+1).toDate()
                            },
                        }

                        // Get new
                        const res = await axios.post(`${this.$store.state.herokuApiLink}/marketplace/apilo/${this.$store.state.herokuApiPath}/orders/get`, payload, {
                            // timeout: 20000,
                            headers: {
                                'x-api-jwt-token': this.$store.state.userData.jwt,
                                'x-api-key': process.env.VUE_APP_APIKEY,
                            },
                        })        

                        // Decide to show button
                        if(res.data.payload.orders.length < 100)
                        {
                            this.apiloMoreToLoad = false;
                        }
                        else
                        {
                            this.apiloMoreToLoad = true;
                        }

                        // Save last UNIX timestamp
                        if(res.data.payload.orders.length > 0)
                        {
                            this.apiloLastLoaded = res.data.payload.orders[res.data.payload.orders.length-1].meta.external.dates.add_TimestampUNIX;
                        }

                        let alreadyDownloadedFlags = [];
                        let flagsData = [];
                        
                        // Find right flag
                        for(let i=0; i< res.data.payload.orders.length;i++)
                        {
                            let current = res.data.payload.orders[i];
                            current.countryFlagURL = "";

                            // Check in existing orders
                            for(let y=0; y < this.apiloBufferOrders.length; y++)
                            {
                                if(current.delivery.countryCode === this.apiloBufferOrders[y].delivery.countryCode)
                                {
                                    current.countryFlagURL = this.apiloBufferOrders[y].countryFlagURL;
                                    break;
                                }
                            }

                            // download if flag not found
                            if(current.countryFlagURL.length === 0)
                            {
                                if(!alreadyDownloadedFlags.includes(current.delivery.countryCode))
                                {
                                    alreadyDownloadedFlags.push(current.delivery.countryCode);
                                    let flagData = {
                                        code: current.delivery.countryCode,
                                        url: await this.getFlag(this.apiloBufferOrders[i].delivery.countryCode)
                                    }
                                    flagsData.push(flagData)
                                    current.countryFlagURL = flagData.url;
                                }
                                else
                                {
                                    // get from already downloaded flags
                                    for(let y=0;y<flagsData.length;y++)
                                    {
                                        if(flagsData[y].code === current.delivery.countryCode)
                                        {
                                            current.countryFlagURL = flagsData[y].url;
                                            break;
                                        }
                                    }
                                }
                            }
                            this.apiloBufferOrders.push(current);
                        }
                    
                    }
                }
            },
            async continueBolPagination()
            {
                if(this.bolMoreToLoad)
                {
                    if(this.bolLastLoaded !== null)
                    {

                        let payload = {
                            whoAmI: this.$store.state.userData.id,
                            unixTimestamp: this.bolLastLoaded+1
                        }

                        // Get new
                        const res = await axios.post(`${this.$store.state.apiLink}/getAwaitingOrdersFromBol`, payload )

                        // Decide to show button
                        if(res.data.bufferOrders.length < 100)
                        {
                            this.bolMoreToLoad = false;
                        }
                        else
                        {
                            this.bolMoreToLoad = true;
                        }

                        // Save last UNIX timestamp
                        if(res.data.bufferOrders.length > 0)
                        {
                            this.bolLastLoaded = res.data.bufferOrders[res.data.bufferOrders.length-1].meta.external.dates.add_TimestampUNIX;
                        }

                        let alreadyDownloadedFlags = [];
                        let flagsData = [];
                        
                        // Find right flag
                        for(let i=0; i< res.data.bufferOrders.length;i++)
                        {
                            let current = res.data.bufferOrders[i];
                            current.countryFlagURL = "";

                            // Check in existing orders
                            for(let y=0; y < this.bolBufferOrders.length; y++)
                            {
                                if(current.delivery.countryCode === this.bolBufferOrders[y].delivery.countryCode)
                                {
                                    current.countryFlagURL = this.bolBufferOrders[y].countryFlagURL;
                                    break;
                                }
                            }

                            // download if flag not found
                            if(current.countryFlagURL.length === 0)
                            {
                                if(!alreadyDownloadedFlags.includes(current.delivery.countryCode))
                                {
                                    alreadyDownloadedFlags.push(current.delivery.countryCode);
                                    let flagData = {
                                        code: current.delivery.countryCode,
                                        url: await this.getFlag(this.bolBufferOrders[i].delivery.countryCode)
                                    }
                                    flagsData.push(flagData)
                                    current.countryFlagURL = flagData.url;
                                }
                                else
                                {
                                    // get from already downloaded flags
                                    for(let y=0;y<flagsData.length;y++)
                                    {
                                        if(flagsData[y].code === current.delivery.countryCode)
                                        {
                                            current.countryFlagURL = flagsData[y].url;
                                            break;
                                        }
                                    }
                                }
                            }
                            this.bolBufferOrders.push(current);
                        }
                    
                    }
                }
            },
            async continueWayfairPagination()
            {
                if(this.wayfairMoreToLoad)
                {
                    if(this.wayfairLastLoaded !== null)
                    {

                        let payload = {
                            whoAmI: this.$store.state.userData.id,
                            unixTimestamp: this.wayfairLastLoaded+1
                        }

                        // Get new
                        const res = await axios.post(`${this.$store.state.apiLink}/getAwaitngOrdersFromWayfair`, payload )

                        // Decide to show button
                        if(res.data.bufferOrders.length < 100)
                        {
                            this.wayfairMoreToLoad = false;
                        }
                        else
                        {
                            this.wayfairMoreToLoad = true;
                        }

                        // Save last UNIX timestamp
                        if(res.data.bufferOrders.length > 0)
                        {
                            this.wayfairLastLoaded = res.data.bufferOrders[res.data.bufferOrders.length-1].meta.external.dates.add_TimestampUNIX;
                        }

                        let alreadyDownloadedFlags = [];
                        let flagsData = [];
                        
                        // Find right flag
                        for(let i=0; i< res.data.bufferOrders.length;i++)
                        {
                            let current = res.data.bufferOrders[i];
                            current.countryFlagURL = "";

                            // Check in existing orders
                            for(let y=0; y < this.wayfairBufferOrders.length; y++)
                            {
                                if(current.delivery.countryCode === this.wayfairBufferOrders[y].delivery.countryCode)
                                {
                                    current.countryFlagURL = this.wayfairBufferOrders[y].countryFlagURL;
                                    break;
                                }
                            }

                            // download if flag not found
                            if(current.countryFlagURL.length === 0)
                            {
                                if(!alreadyDownloadedFlags.includes(current.delivery.countryCode))
                                {
                                    alreadyDownloadedFlags.push(current.delivery.countryCode);
                                    let flagData = {
                                        code: current.delivery.countryCode,
                                        url: await this.getFlag(this.wayfairBufferOrders[i].delivery.countryCode)
                                    }
                                    flagsData.push(flagData)
                                    current.countryFlagURL = flagData.url;
                                }
                                else
                                {
                                    // get from already downloaded flags
                                    for(let y=0;y<flagsData.length;y++)
                                    {
                                        if(flagsData[y].code === current.delivery.countryCode)
                                        {
                                            current.countryFlagURL = flagsData[y].url;
                                            break;
                                        }
                                    }
                                }
                            }
                            this.wayfairBufferOrders.push(current);
                        }
                    
                    }
                }
            },
            async continueHoodPagination()
            {
                if(this.hoodMoreToLoad)
                {
                    if(this.hoodLastLoaded !== null)
                    {

                        let payload = {
                            whoAmI: this.$store.state.userData.id,
                            unixTimestamp: this.hoodLastLoaded+1
                        }

                        // Get new
                        const res = await axios.post(`${this.$store.state.apiLink}/getAwaitngOrdersFromHood`, payload )

                        // Decide to show button
                        if(res.data.bufferOrders.length < 100)
                        {
                            this.hoodMoreToLoad = false;
                        }
                        else
                        {
                            this.hoodMoreToLoad = true;
                        }

                        // Save last UNIX timestamp
                        if(res.data.bufferOrders.length > 0)
                        {
                            this.hoodLastLoaded = res.data.bufferOrders[res.data.bufferOrders.length-1].meta.external.dates.add_TimestampUNIX;
                        }

                        let alreadyDownloadedFlags = [];
                        let flagsData = [];
                        
                        // Find right flag
                        for(let i=0; i< res.data.bufferOrders.length;i++)
                        {
                            let current = res.data.bufferOrders[i];
                            current.countryFlagURL = "";

                            // Check in existing orders
                            for(let y=0; y < this.hoodBufferOrders.length; y++)
                            {
                                if(current.delivery.countryCode === this.hoodBufferOrders[y].delivery.countryCode)
                                {
                                    current.countryFlagURL = this.hoodBufferOrders[y].countryFlagURL;
                                    break;
                                }
                            }

                            // download if flag not found
                            if(current.countryFlagURL.length === 0)
                            {
                                if(!alreadyDownloadedFlags.includes(current.delivery.countryCode))
                                {
                                    alreadyDownloadedFlags.push(current.delivery.countryCode);
                                    let flagData = {
                                        code: current.delivery.countryCode,
                                        url: await this.getFlag(this.hoodBufferOrders[i].delivery.countryCode)
                                    }
                                    flagsData.push(flagData)
                                    current.countryFlagURL = flagData.url;
                                }
                                else
                                {
                                    // get from already downloaded flags
                                    for(let y=0;y<flagsData.length;y++)
                                    {
                                        if(flagsData[y].code === current.delivery.countryCode)
                                        {
                                            current.countryFlagURL = flagsData[y].url;
                                            break;
                                        }
                                    }
                                }
                            }
                            this.hoodBufferOrders.push(current);
                        }
                    
                    }
                }
            },
            async reloadBlOrders()
            {
                let mDate = moment(this.$refs.datePickerBaselinker.value,"YYYY-MM-DD")
                if(mDate.isValid())
                {
                    this.lastDateToTracker = this.$refs.datePickerBaselinker.value;
                    let unix = mDate.unix();
                    await this.getAwaitingBaselinkerOrders(unix)
                }
                else
                {
                    this.$store.commit('setNotification',{
                    show: true,
                    head: "Błędna data!",
                    subheader: `Popraw datę, aby kontynuować..`,
                    success: false
                    });                    
                } 
            },
            async reloadApiloOrders()
            {
                let mDate = moment(this.$refs.datePickerBaselinker.value,"YYYY-MM-DD")
                if(mDate.isValid())
                {
                    this.lastDateToTracker = this.$refs.datePickerBaselinker.value;
                    let unix = mDate.unix();
                    await this.getAwaitingApiloOrders(unix)
                }
                else
                {
                    this.$store.commit('setNotification',{
                    show: true,
                    head: "Błędna data!",
                    subheader: `Popraw datę, aby kontynuować..`,
                    success: false
                    });                    
                } 
            },
            async reloadOttoOrders()
            {
                let mDate = moment(this.$refs.datePickerBaselinker.value,"YYYY-MM-DD")
                if(mDate.isValid())
                {
                    this.lastDateToTracker = this.$refs.datePickerBaselinker.value;
                    let unix = mDate.unix();
                    await this.getAwaitingOttoOrders(unix)
                }
                else
                {
                    this.$store.commit('setNotification',{
                    show: true,
                    head: "Błędna data!",
                    subheader: `Popraw datę, aby kontynuować..`,
                    success: false
                    });                    
                } 
            },
            async reloadBolOrders(type)
            {
                let mDate = moment(this.$refs.datePickerBaselinker.value,"YYYY-MM-DD")
                if(mDate.isValid())
                {
                    this.lastDateToTracker = this.$refs.datePickerBaselinker.value;
                    let unix = mDate.unix();
                    if(type === 'exact')
                    {
                        await this.getAwaitingBolOrders(unix, type)
                        await this.getDoneBolOrders();
                    }
                    else
                    {
                        this.exactSearcher = false;
                        await this.getAwaitingBolOrders(unix)
                    }
                }
                else
                {
                    this.$store.commit('setNotification',{
                    show: true,
                    head: "Błędna data!",
                    subheader: `Popraw datę, aby kontynuować..`,
                    success: false
                    });                    
                } 
            },
            async reloadWayfairOrders()
            {
                let mDate = moment(this.$refs.datePickerBaselinker.value,"YYYY-MM-DD")
                if(mDate.isValid())
                {
                    this.lastDateToTracker = this.$refs.datePickerBaselinker.value;
                    let unix = mDate.unix();
                    await this.getAwaitingWayfairOrders(unix)
                }
                else
                {
                    this.$store.commit('setNotification',{
                    show: true,
                    head: "Błędna data!",
                    subheader: `Popraw datę, aby kontynuować..`,
                    success: false
                    });                    
                } 
            },
            async reloadHoodOrders()
            {
                let mDate = moment(this.$refs.datePickerBaselinker.value,"YYYY-MM-DD")
                if(mDate.isValid())
                {
                    this.lastDateToTracker = this.$refs.datePickerBaselinker.value;
                    let unix = mDate.unix();
                    await this.getAwaitingHoodOrders(unix)
                }
                else
                {
                    this.$store.commit('setNotification',{
                    show: true,
                    head: "Błędna data!",
                    subheader: `Popraw datę, aby kontynuować..`,
                    success: false
                    });                    
                } 
            },
            setDatePickersOnStart()
            {
                if(this.$refs.datePickerBaselinker !== undefined)
                {
                    let current = moment().format("YYYY-MM-DD");
                    this.lastDateToTracker = current;
                    this.$refs.datePickerBaselinker.value = current;
                }
            },
            sendToBaselinkerPage(url)
            {
                if(url === undefined) return;
                if(url === null) return ;
                if(url.length === 0) return;
                window.open(url, '_blank');
            },
            sendToBolPage(url)
            {
                if(url === undefined) return;
                if(url === null) return ;
                if(url.length === 0) return;
                window.open(url, '_blank');
            },
            sendToWayfairPage(url)
            {
                if(url === undefined) return;
                if(url === null) return ;
                if(url.length === 0) return;
                window.open(url, '_blank');
            },
            sendToHoodPage(url)
            {
                if(url === undefined) return;
                if(url === null) return ;
                if(url.length === 0) return;
                window.open(url, '_blank');
            },
            orderPlacedDateFromUNIX(unix)
            {
                if(unix === undefined) return "--"
                if(unix === null) return "--"
                if(unix === 0) return "Brak"
                let date = moment(unix*1000).format("DD.MM.YY HH:mm");
                return date;
            },
            amountOfItems(positions)
            {
                let posAm = 0;
                let items = 0;
                for (const pos in positions) {
                    posAm = posAm +1
                    items = items + positions[pos].product.quantity;
                }

                return `${posAm} poz. (${items} przedmiotów)`
            },
            totalPrice(positions, currency, deliveryPrice)
            {
                let totalPrice = 0;
                for (const pos in positions) {
                    totalPrice = totalPrice + (positions[pos].product.quantity * positions[pos].product.pricePerItemTaxIncluded)
                }
                if(deliveryPrice > 0)
                {
                    totalPrice = parseFloat(totalPrice) + parseFloat(deliveryPrice);
                }
                return `${totalPrice.toFixed(2)} ${currency}`
            },
            async getFlag(code)
            {
                if(code.length === 0) return "";
                let flagURL = "";
                const resCountries = await axios.get(`${this.$store.state.apiLink}/data/getCountries`)
                if(resCountries.data.status === 'SUCCESS')
                {
                    if(resCountries.data.countries != null)
                    {
                        let countries = resCountries.data.countries;
                        countries.forEach(country => {
                            if(country.countryCode === code)
                            {
                                if(country.flagURL != null && country.flagURL.length > 0)
                                {
                                    flagURL = country.flagURL;
                                }
                            }
                        });
                    }
                };
                return flagURL
            },
            sendEmail(mail)
            {
                if(mail === undefined) return;
                if(mail === null) return;
                if(mail.length === 0) return;
                window.location.href = `mailto:${mail}`;
            },
            call(num)
            {
                if(num === undefined) return;
                if(num === null) return;
                if(num.length === 0) return;
                window.location.href = `callto:${num}`;
            },
            async getAwaitingBaselinkerOrders(unix = undefined)
            {
                this.isMarketplace = {
                    bl: true,
                    apilo: false,
                    bol: false,
                    wayfair: false,
                    hood: false,
                    otto: false,
                };
                this.isDisabled = true;
                this.bolTable = false;
                this.bolLoaded = false;
                this.wayfairTable = false;
                this.wayfairLoaded = false;
                this.hoodTable = false;
                this.hoodLoaded = false;
                this.blTable = false;
                this.blLoaded = false;
                this.apiloTable = false;
                this.apiloLoaded = false;
                this.ottoLoaded = false;
                this.baselinkerBufferOrders = [];
                
                let payload = {
                    whoAmI: this.$store.state.userData.id,
                    specific: false,
                }

                if(unix !== undefined)
                {
                    payload.unixTimestamp = unix;
                }

                const res = await axios.post(`${this.$store.state.apiLink}/getAwaitngOrdersFromBaselinker`, payload )
                if(res.data.success === true)
                {
                    // alert("Hello")
                    let countriesCodes = []
                    let countriesData = [];
                    this.baselinkerBufferOrders = res.data.bufferOrders;

                    if(this.baselinkerBufferOrders.length > 0)
                    {
                        this.baselinkerLastLoaded = this.baselinkerBufferOrders[this.baselinkerBufferOrders.length-1].meta.external.dates.add_TimestampUNIX;
                    }

                    for(let i=0; i<this.baselinkerBufferOrders.length; i++)
                    {
                        this.baselinkerBufferOrders[i].countryFlagURL = "";
                        if(this.baselinkerBufferOrders[i].delivery.countryCode.length === 0 && this.baselinkerBufferOrders[i].invoice.countryCode.length > 0)
                        {
                            this.baselinkerBufferOrders[i].delivery.countryCode = this.baselinkerBufferOrders[i].invoice.countryCode;
                        }
                        if(!countriesCodes.includes(this.baselinkerBufferOrders[i].delivery.countryCode) && this.baselinkerBufferOrders[i].delivery.countryCode !== "")
                        {
                            let flag = await this.getFlag(this.baselinkerBufferOrders[i].delivery.countryCode)
                            countriesData.push({code: this.baselinkerBufferOrders[i].delivery.countryCode, flagURL: flag})
                        }
                        for(let y=0; y< countriesData.length; y++)
                        {
                            if(countriesData[y].code === this.baselinkerBufferOrders[i].delivery.countryCode)
                            {
                                this.baselinkerBufferOrders[i].countryFlagURL = countriesData[y].flagURL;
                            }
                            else
                            {
                                this.baselinkerBufferOrders[i].countryFlagURL = "";
                            }
                        }
                    }

                    if(this.baselinkerBufferOrders.length === 100)
                    {
                        this.baselinkerMoreToLoad = true;
                    }
                    
                    this.blLoaded = true;
                    this.blTable = true;
                    this.isDisabled = false;
                    this.$store.commit('setNotification',{
                    show: true,
                    head: "Pobrano zamówienia!",
                    subheader: `Pobrano: ${res.data.bufferOrders.length}`,
                    success: true
                    });
                }
                else
                {
                    this.blLoaded = true;
                    this.blTable = false;
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Pobieranie oczekujących zamówień nie powiodło się!",
                        subheader: `Wystąpił nieoczekiwany błąd.`,
                        success: false
                    });
                    this.clearData();
                }
            },
            async getAwaitingApiloOrders(unix = undefined)
            {
                try {
                    this.isMarketplace = {
                        bl: false,
                        apilo: true,
                        bol: false,
                        wayfair: false,
                        hood: false,
                        otto: false,
                    };
                    this.lastDateToTracker = this.$refs.datePickerBaselinker.value;
                    this.isDisabled = true;
                    this.bolTable = false;
                    this.bolLoaded = false;
                    this.wayfairTable = false;
                    this.wayfairLoaded = false;
                    this.hoodTable = false;
                    this.hoodLoaded = false;
                    this.blTable = false;
                    this.blLoaded = false;
                    this.apiloTable = false;
                    this.apiloLoaded = false;
                    this.ottoLoaded = false;
                    this.apiloBufferOrders = [];
                    let funcitonalTracker = {};

                    let dateNow = moment().format('YYYY-MM-DD');

                    if(dateNow === this.lastDateToTracker)
                    {
                        const resultOfTrackerData = await db.collection("AwaitingOrderDone_apilo").doc(`${this.lastDateToTracker}`).get().catch((error)=>
                        {
                            this.isDoneTrackerDoc = null;
                        });
    
                        if(resultOfTrackerData.exists)
                        {
                            this.isDoneTrackerDoc = null;
                            this.isDoneTrackerDoc = resultOfTrackerData.data();
                        }
                        else
                        {
                            this.isDoneTrackerDoc = null;
                        }
                    }
                    else
                    {
                        const resultOfTrackerData = await db.collection("AwaitingOrderDone_apilo").get().catch((error)=>
                        {
                            this.isDoneTrackerDoc = null;
                        });

                        if(resultOfTrackerData.empty)
                        {
                            this.isDoneTrackerDoc = null;
                        }
                        else
                        {
                            resultOfTrackerData.docs.forEach(doc => {
                                let current = doc.data();
                                let everyDate = Object.entries(current);
                                everyDate.forEach(doc => {
                                    funcitonalTracker[doc[0]] = doc[1]
                                });
                            });
                        }
                        this.isDoneTrackerDoc = funcitonalTracker;
                    }

                    let payload = {
                        whoAmI: this.$store.state.userData.id,
                        specific: false,
                        options: {},
                    }
                    if(unix !== undefined)
                    {
                        payload.options.createdAfter = moment.unix(unix).toDate();
                        payload.options.createdBefore = moment.unix(unix).add(1, 'days').startOf('day').toDate();
                    }

                    const res = await axios.post(`${this.$store.state.herokuApiLink}/marketplace/apilo/${this.$store.state.herokuApiPath}/allOrders/get`, payload, {
                        // timeout: 20000,
                        headers: {
                            'x-api-jwt-token': this.$store.state.userData.jwt,
                            'x-api-key': process.env.VUE_APP_APIKEY,
                        },
                    })                    
                    if(res.data.success)
                    {
                        let countriesCodes = []
                        let countriesData = [];

                        this.apiloBufferOrders = res.data.payload.orders;

                        // this.apiloBufferOrders = res.data.payload.orders.filter(awOrd => {
                        //     return this.isDoneTrackerDoc == null ? awOrd : this.isDoneTrackerDoc[`${awOrd.meta.external.ids.apiloOrderId}`] === undefined;
                        // });
                                
                        if(this.apiloBufferOrders.length > 0)
                        {
                            this.apiloLastLoaded = this.apiloBufferOrders[this.apiloBufferOrders.length-1].meta.external.dates.add_TimestampUNIX;
                        }

                        if(this.apiloBufferOrders.length === 100)
                        {
                            this.apiloMoreToLoad = true;
                        }

                        for(let i=0; i<this.apiloBufferOrders.length; i++)
                        {
                            this.apiloBufferOrders[i].countryFlagURL = "";
                            if(this.apiloBufferOrders[i].delivery.countryCode.length === 0 && this.apiloBufferOrders[i].invoice.countryCode.length > 0)
                            {
                                this.apiloBufferOrders[i].delivery.countryCode = this.apiloBufferOrders[i].invoice.countryCode;
                            }
                            if(!countriesCodes.includes(this.apiloBufferOrders[i].delivery.countryCode) && this.apiloBufferOrders[i].delivery.countryCode !== "")
                            {
                                let flag = await this.getFlag(this.apiloBufferOrders[i].delivery.countryCode)
                                countriesData.push({code: this.apiloBufferOrders[i].delivery.countryCode, flagURL: flag})
                            }
                            for(let y=0; y< countriesData.length; y++)
                            {
                                if(countriesData[y].code === this.apiloBufferOrders[i].delivery.countryCode)
                                {
                                    this.apiloBufferOrders[i].countryFlagURL = countriesData[y].flagURL;
                                }
                                else
                                {
                                    this.apiloBufferOrders[i].countryFlagURL = "";
                                }
                            }
                        }

                        this.apiloLoaded = true;
                        this.apiloTable = true;
                        this.isDisabled = false;
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Pobrano zamówienia!",
                            subheader: `Pobrano: ${this.apiloBufferOrders.length}`,
                            success: true
                        });
                    }
                    else
                    { 
                        throw 'Pobieranie oczekujących zamówień nie powiodło się!';
                    }
                } catch (error) {
                    console.log(error);
                    this.apiloLoaded = true;
                    this.apiloTable = false;
                    this.clearData();
                    this.$store.commit('setNotification',{
                        show: true,
                        head: error,
                        subheader: `Wystąpił nieoczekiwany błąd.`,
                        success: false
                    });
                }
            },
            async getAwaitingOttoOrders(unix = undefined)
            {
                try {
                    this.isMarketplace = {
                        bl: false,
                        apilo: false,
                        bol: false,
                        wayfair: false,
                        hood: false,
                        otto: true,
                    };
                    this.lastDateToTracker = this.$refs.datePickerBaselinker.value;
                    this.isDisabled = true;
                    this.bolTable = false;
                    this.bolLoaded = false;
                    this.wayfairTable = false;
                    this.wayfairLoaded = false;
                    this.hoodTable = false;
                    this.hoodLoaded = false;
                    this.blTable = false;
                    this.blLoaded = false;
                    this.apiloTable = false;
                    this.apiloLoaded = false;
                    this.ottoLoaded = false;
                    this.ottoBufferOrders = [];
                    let funcitonalTracker = {};

                    let dateNow = moment().format('YYYY-MM-DD');
                    if(dateNow === this.lastDateToTracker)
                    {
                        const resultOfTrackerData = await db.collection("AwaitingOrderDone_otto").doc(`${this.lastDateToTracker}`).get().catch((error)=>
                        {
                            this.isDoneTrackerDoc = null;
                        });
    
                        if(resultOfTrackerData.exists)
                        {
                            this.isDoneTrackerDoc = null;
                            this.isDoneTrackerDoc = resultOfTrackerData.data();
                        }
                        else
                        {
                            this.isDoneTrackerDoc = null;
                        }
                    }
                    else
                    {
                        const resultOfTrackerData = await db.collection("AwaitingOrderDone_otto").get().catch((error)=>
                        {
                            this.isDoneTrackerDoc = null;
                        });

                        if(resultOfTrackerData.empty)
                        {
                            this.isDoneTrackerDoc = null;
                        }
                        else
                        {
                            resultOfTrackerData.docs.forEach(doc => {
                                let current = doc.data();
                                let everyDate = Object.entries(current);
                                everyDate.forEach(doc => {
                                    funcitonalTracker[doc[0]] = doc[1]
                                });
                            });
                        }
                        this.isDoneTrackerDoc = funcitonalTracker;
                    }

                    let payload = {
                        whoAmI: this.$store.state.userData.id,
                        specific: false,
                        options: {},
                    }
                    if(unix !== undefined)
                    {
                        payload.options.createdAfter = unix;
                        payload.options.status = this.pickedStatus.otto;
                    }
                    
                    const res = await axios.post(`${this.$store.state.herokuApiLink}/marketplace/ottomarket/${this.$store.state.herokuApiPath}/orders/get`, payload, {
                        headers: {
                            'x-api-jwt-token': this.$store.state.userData.jwt,
                            'x-api-key': process.env.VUE_APP_APIKEY,
                        },
                    })                    
                    if(res.data.success)
                    {
                        let countriesCodes = []
                        let countriesData = [];

                        this.ottoBufferOrders = res.data.payload.orders

                        // this.ottoBufferOrders = res.data.payload.orders.filter(awOrd => {
                        //     return this.isDoneTrackerDoc == null ? awOrd : this.isDoneTrackerDoc[`${awOrd.meta.external.ids.ottoOrderId}`] === undefined;
                        // });

                        if(this.ottoBufferOrders.length > 0)
                        {
                            this.ottoLastLoaded = this.ottoBufferOrders[this.ottoBufferOrders.length-1].meta.external.dates.add_TimestampUNIX;
                        }

                        if(this.ottoBufferOrders.length === 128)
                        {
                            this.ottoMoreToLoad = true;
                        }

                        for(let i=0; i<this.ottoBufferOrders.length; i++)
                        {
                            this.ottoBufferOrders[i].countryFlagURL = "";
                            if(this.ottoBufferOrders[i].delivery.countryCode.length === 0 && this.ottoBufferOrders[i].invoice.countryCode.length > 0)
                            {
                                this.ottoBufferOrders[i].delivery.countryCode = this.ottoBufferOrders[i].invoice.countryCode;
                            }
                            if(!countriesCodes.includes(this.ottoBufferOrders[i].delivery.countryCode) && this.ottoBufferOrders[i].delivery.countryCode !== "")
                            {
                                countriesData.push({code: this.ottoBufferOrders[i].delivery.countryCode, flagURL: this.ottoBufferOrders[i].delivery.flagURL})
                            }
                            for(let y=0; y< countriesData.length; y++)
                            {
                                if(countriesData[y].code === this.ottoBufferOrders[i].delivery.countryCode)
                                {
                                    this.ottoBufferOrders[i].countryFlagURL = countriesData[y].flagURL;
                                }
                                else
                                {
                                    this.ottoBufferOrders[i].countryFlagURL = "";
                                }
                            }
                        }

                        this.ottoLoaded = true;
                        this.isDisabled = false;
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Pobrano zamówienia!",
                            subheader: `Pobrano: ${this.ottoBufferOrders.length}`,
                            success: true
                        });
                    }
                    else
                    { 
                        throw 'Pobieranie oczekujących zamówień nie powiodło się!';
                    }
                } catch (error) {
                    console.log(error);
                    this.ottoLoaded = true;
                    this.clearData();
                    this.$store.commit('setNotification',{
                        show: true,
                        head: error,
                        subheader: `Wystąpił nieoczekiwany błąd.`,
                        success: false
                    });
                }
            },
            async getAwaitingBolOrders(unix = undefined, exact)
            {
                this.isMarketplace = {
                    bl: false,
                    apilo: false,
                    bol: true,
                    wayfair: false,
                    hood: false,
                    otto: false,
                };
                this.lastDateToTracker = this.$refs.datePickerBaselinker.value;
                this.isDisabled = true;
                this.bolTable = false;
                this.bolLoaded = false;
                this.wayfairTable = false;
                this.wayfairLoaded = false;
                this.hoodTable = false;
                this.hoodLoaded = false;
                this.blTable = false;
                this.blLoaded = false;
                this.apiloTable = false;
                this.apiloLoaded = false;
                this.ottoLoaded = false;
                this.bolBufferOrders = [];
                let funcitonalTracker = {};
                
                let dateNow = moment().format('YYYY-MM-DD');

                if(dateNow === this.lastDateToTracker)
                {
                    const resultOfTrackerData = await db.collection("AwaitingOrderDone_bol").doc(`${this.lastDateToTracker}`).get().catch((error)=>
                    {
                        this.isDoneTrackerDoc = null;
                    });

                    if(resultOfTrackerData.exists)
                    {
                        this.isDoneTrackerDoc = null;
                        this.isDoneTrackerDoc = resultOfTrackerData.data();
                    }
                    else
                    {
                        this.isDoneTrackerDoc = null;
                    }
                }
                else
                {
                    const resultOfTrackerData = await db.collection("AwaitingOrderDone_bol").get().catch((error)=>
                    {
                        this.isDoneTrackerDoc = null;
                    });

                    if(resultOfTrackerData.empty)
                    {
                        this.isDoneTrackerDoc = null;
                    }
                    else
                    {
                        resultOfTrackerData.docs.forEach(doc => {
                            let current = doc.data();
                            let everyDate = Object.entries(current);
                            everyDate.forEach(doc => {
                                funcitonalTracker[doc[0]] = doc[1]
                            });
                        });
                    }
                    this.isDoneTrackerDoc = funcitonalTracker;
                }
                
                let payload = {
                    whoAmI: this.$store.state.userData.id
                }

                if(unix !== undefined)
                {
                    payload.unixTimestamp = unix;
                }

                if(exact !== undefined)
                {
                    payload.exact = this.orderId;
                }

                // Get tracker doc
                // await db.collection("AwaitingOrderDone_bol").doc(``)

                const res = await axios.post(`${this.$store.state.apiLink}/getAwaitingOrdersFromBol`, payload )
                if(res.data.bufferOrders !== undefined)
                {
                    // alert("Hello")
                    let countriesCodes = []
                    let countriesData = [];

                    // if(exact != null)
                    // {
                    //     this.bolBufferOrders = res.data.bufferOrders;
                    // }
                    // else
                    // {
                    //     this.bolBufferOrders = res.data.bufferOrders.filter(awOrd => {
                    //         return this.isDoneTrackerDoc == null ? awOrd : this.isDoneTrackerDoc[`${awOrd.meta.external.ids.bolOrderId}`] === undefined;
                    //     });
                    // }

                    this.bolBufferOrders = res.data.bufferOrders;

                    for (let i = 0; i < this.bolBufferOrders.length; i++) {
                        this.bolBufferOrders[i].meta.external.dates.add_TimestampUNIX = moment(this.bolBufferOrders[i].meta.external.dates.add_TimestampUNIX).unix()
                    }
                    
                    if(this.bolBufferOrders.length > 0)
                    {
                        this.bolLastLoaded = this.bolBufferOrders[this.bolBufferOrders.length-1].meta.external.dates.add_TimestampUNIX;
                    }

                    for(let i=0; i<this.bolBufferOrders.length; i++)
                    {
                        this.bolBufferOrders[i].countryFlagURL = "";
                        if(this.bolBufferOrders[i].delivery.countryCode.length === 0 && this.bolBufferOrders[i].invoice.countryCode.length > 0)
                        {
                            this.bolBufferOrders[i].delivery.countryCode = this.bolBufferOrders[i].invoice.countryCode;
                        }
                        if(!countriesCodes.includes(this.bolBufferOrders[i].delivery.countryCode) && this.bolBufferOrders[i].delivery.countryCode !== "")
                        {
                            let flag = await this.getFlag(this.bolBufferOrders[i].delivery.countryCode)
                            countriesData.push({code: this.bolBufferOrders[i].delivery.countryCode, flagURL: flag})
                        }
                        for(let y=0; y< countriesData.length; y++)
                        {
                            if(countriesData[y].code === this.bolBufferOrders[i].delivery.countryCode)
                            {
                                this.bolBufferOrders[i].countryFlagURL = countriesData[y].flagURL;
                            }
                            else
                            {
                                this.bolBufferOrders[i].countryFlagURL = "";
                            }
                        }
                    }

                    if(this.bolBufferOrders.length === 100)
                    {
                        this.bolMoreToLoad = true;
                    }
                    
                    this.bolLoaded = true;
                    this.bolTable = true;
                    this.isDisabled = false;
                    if(!res.data.success)
                    {
                        this.$store.commit('setNotification',{
                        show: true,
                        head: "Coś poszło nie tak!",
                        subheader: `${res.data.log.detail}`,
                        success: false
                        });
                    }
                    else
                    {
                        this.$store.commit('setNotification',{
                        show: true,
                        head: "Pobrano zamówienia!",
                        subheader: `Pobrano: ${this.bolBufferOrders.length}`,
                        success: true
                        });
                    }
                }
                else
                {
                    this.bolLoaded = true;
                    this.bolTable = false;
                    this.$store.commit('setNotification',{
                    show: true,
                    head: "Pobieranie oczekujących zamówień nie powiodło się!",
                    subheader: `Wystąpił nieoczekiwany błąd.`,
                    success: false
                    });
                }
            },
            async getAwaitingWayfairOrders(unix = undefined)
            {
                this.isMarketplace = {
                    bl: false,
                    apilo: false,
                    bol: false,
                    wayfair: true,
                    hood: false,
                    otto: false,
                };
                this.lastDateToTracker = this.$refs.datePickerBaselinker.value;
                this.isDisabled = true;
                this.blTable = false;
                this.blLoaded = false;
                this.bolTable = false;
                this.bolLoaded = false;
                this.hoodTable = false;
                this.hoodLoaded = false;
                this.wayfairTable = false;
                this.wayfairLoaded = false;
                this.apiloTable = false;
                this.ottoLoaded = false;
                this.apiloLoaded = false;
                this.wayfairBufferOrders = [];
                let funcitonalTracker = {};
                
                let payload = {
                    whoAmI: this.$store.state.userData.id
                }

                if(unix !== undefined)
                {
                    payload.unixTimestamp = unix;
                }

                const res = await axios.post(`${this.$store.state.apiLink}/getAwaitingOrdersFromWayfair`, payload )
                
                if(res.data.bufferOrders !== undefined)
                {
                    let dateNow = moment().format('YYYY-MM-DD');

                    if(dateNow === this.lastDateToTracker)
                    {
                        const resultOfTrackerData = await db.collection("AwaitingOrderDone_wayfair").doc(`${this.lastDateToTracker}`).get().catch((error)=>
                        {
                            this.isDoneTrackerDoc = null;
                        });

                        if(resultOfTrackerData.exists)
                        {
                            this.isDoneTrackerDoc = null;
                            this.isDoneTrackerDoc = resultOfTrackerData.data();
                        }
                        else
                        {
                            this.isDoneTrackerDoc = null;
                        }
                    }
                    else
                    {
                        const resultOfTrackerData = await db.collection("AwaitingOrderDone_wayfair").get().catch((error)=>
                        {
                            this.isDoneTrackerDoc = null;
                        });

                        if(resultOfTrackerData.empty)
                        {
                            this.isDoneTrackerDoc = null;
                        }
                        else
                        {
                            resultOfTrackerData.docs.forEach(doc => {
                                let current = doc.data();
                                let everyDate = Object.entries(current);
                                everyDate.forEach(doc => {
                                    funcitonalTracker[doc[0]] = doc[1]
                                });
                            });
                        }
                        this.isDoneTrackerDoc = funcitonalTracker;
                    }

                    // alert("Hello")
                    let countriesCodes = []
                    let countriesData = [];
                    // this.wayfairBufferOrders = res.data.bufferOrders;

                    this.wayfairBufferOrders = res.data.bufferOrders.filter(awOrd => {
                        return this.isDoneTrackerDoc == null ? awOrd : this.isDoneTrackerDoc[`${awOrd.meta.external.ids.wayfairOrderId}`] === undefined;
                    });

                    for (let i = 0; i < this.wayfairBufferOrders.length; i++) {
                        this.wayfairBufferOrders[i].meta.external.dates.add_TimestampUNIX = moment(this.wayfairBufferOrders[i].meta.external.dates.add_TimestampUNIX).unix()
                    }
                    
                    if(this.wayfairBufferOrders.length > 0)
                    {
                        this.wayfairLastLoaded = this.wayfairBufferOrders[this.wayfairBufferOrders.length-1].meta.external.dates.add_TimestampUNIX;
                    }

                    for(let i=0; i<this.wayfairBufferOrders.length; i++)
                    {
                        this.wayfairBufferOrders[i].countryFlagURL = "";
                        if(this.wayfairBufferOrders[i].delivery.countryCode.length === 0 && this.wayfairBufferOrders[i].invoice.countryCode.length > 0)
                        {
                            this.wayfairBufferOrders[i].delivery.countryCode = this.wayfairBufferOrders[i].invoice.countryCode;
                        }
                        if(!countriesCodes.includes(this.wayfairBufferOrders[i].delivery.countryCode) && this.wayfairBufferOrders[i].delivery.countryCode !== "")
                        {
                            let flag = await this.getFlag(this.wayfairBufferOrders[i].delivery.countryCode)
                            countriesData.push({code: this.wayfairBufferOrders[i].delivery.countryCode, flagURL: flag})
                        }
                        for(let y=0; y< countriesData.length; y++)
                        {
                            if(countriesData[y].code === this.wayfairBufferOrders[i].delivery.countryCode)
                            {
                                this.wayfairBufferOrders[i].countryFlagURL = countriesData[y].flagURL;
                            }
                            else
                            {
                                this.wayfairBufferOrders[i].countryFlagURL = "";
                            }
                        }
                    }

                    if(this.wayfairBufferOrders.length === 100)
                    {
                        this.wayfairMoreToLoad = true;
                    }
                    
                    this.wayfairLoaded = true;
                    this.wayfairTable = true;
                    this.isDisabled = false;
                    this.$store.commit('setNotification',{
                    show: true,
                    head: "Pobrano zamówienia!",
                    subheader: `Pobrano: ${this.wayfairBufferOrders.length}`,
                    success: true
                    });
                }
                else
                {
                    this.wayfairLoaded = true;
                    this.wayfairTable = false;
                    this.$store.commit('setNotification',{
                    show: true,
                    head: "Pobieranie oczekujących zamówień nie powiodło się!",
                    subheader: `Wystąpił nieoczekiwany błąd.`,
                    success: false
                    });
                }
            },
            async getAwaitingHoodOrders(unix = undefined)
            {
                this.isMarketplace = {
                    bl: false,
                    apilo: false,
                    bol: false,
                    wayfair: false,
                    hood: true,
                    otto: false,
                };
                this.lastDateToTracker = this.$refs.datePickerBaselinker.value;
                this.isDisabled = true;
                this.blTable = false;
                this.blLoaded = false;
                this.bolTable = false;
                this.bolLoaded = false;
                this.wayfairTable = false;
                this.wayfairLoaded = false;
                this.hoodTable = false;
                this.hoodLoaded = false;
                this.apiloTable = false;
                this.apiloLoaded = false;
                this.ottoLoaded = false;
                this.hoodBufferOrders = [];
                let funcitonalTracker = {};

                let payload = {
                    whoAmI: this.$store.state.userData.id
                }

                if(unix !== undefined)
                {
                    payload.unixTimestamp = unix;
                }


                const res = await axios.post(`${this.$store.state.apiLink}/getAwaitingOrdersFromHood`, payload )

                if(res.data.success === true)
                {
                    let dateNow = moment().format('YYYY-MM-DD');

                    if(dateNow === this.lastDateToTracker)
                    {
                        const resultOfTrackerData = await db.collection("AwaitingOrderDone_hood").doc(`${this.lastDateToTracker}`).get().catch((error)=>
                        {
                            this.isDoneTrackerDoc = null;
                        });

                        if(resultOfTrackerData.exists)
                        {
                            this.isDoneTrackerDoc = null;
                            this.isDoneTrackerDoc = resultOfTrackerData.data();
                        }
                        else
                        {
                            this.isDoneTrackerDoc = null;
                        }
                    }
                    else
                    {
                        const resultOfTrackerData = await db.collection("AwaitingOrderDone_hood").get().catch((error)=>
                        {
                            this.isDoneTrackerDoc = null;
                        });

                        if(resultOfTrackerData.empty)
                        {
                            this.isDoneTrackerDoc = null;
                        }
                        else
                        {
                            resultOfTrackerData.docs.forEach(doc => {
                                let current = doc.data();
                                let everyDate = Object.entries(current);
                                everyDate.forEach(doc => {
                                    funcitonalTracker[doc[0]] = doc[1]
                                });
                            });
                        }
                        this.isDoneTrackerDoc = funcitonalTracker;
                    }

                    // alert("Hello")
                    let countriesCodes = [];
                    let countriesData = [];

                    // this.hoodBufferOrders = res.data.bufferOrders;

                    this.hoodBufferOrders = res.data.bufferOrders.filter(awOrd => {
                        return this.isDoneTrackerDoc == null ? awOrd : this.isDoneTrackerDoc[`${awOrd.meta.external.ids.hoodOrderId}`] === undefined;
                    });

                    if(this.hoodBufferOrders.length > 0)
                    {
                        this.hoodLastLoaded = this.hoodBufferOrders[this.hoodBufferOrders.length-1].meta.external.dates.add_TimestampUNIX;
                    }

                    for(let i=0; i<this.hoodBufferOrders.length; i++)
                    {
                        this.hoodBufferOrders[i].countryFlagURL = "";
                        if(this.hoodBufferOrders[i].delivery.countryCode.length === 0 && this.hoodBufferOrders[i].invoice.countryCode.length > 0)
                        {
                            this.hoodBufferOrders[i].delivery.countryCode = this.hoodBufferOrders[i].invoice.countryCode;
                        }
                        if(!countriesCodes.includes(this.hoodBufferOrders[i].delivery.countryCode) && this.hoodBufferOrders[i].delivery.countryCode !== "")
                        {
                            let flag = await this.getFlag(this.hoodBufferOrders[i].delivery.countryCode)
                            countriesData.push({code: this.hoodBufferOrders[i].delivery.countryCode, flagURL: flag})
                        }
                        for(let y=0; y< countriesData.length; y++)
                        {
                            if(countriesData[y].code === this.hoodBufferOrders[i].delivery.countryCode)
                            {
                                this.hoodBufferOrders[i].countryFlagURL = countriesData[y].flagURL;
                            }
                            else
                            {
                                this.hoodBufferOrders[i].countryFlagURL = "";
                            }
                        }
                    }

                    if(this.hoodBufferOrders.length === 100)
                    {
                        this.hoodMoreToLoad = true;
                    }
                    
                    this.hoodLoaded = true;
                    this.hoodTable = true;
                    this.isDisabled = false;
                    this.$store.commit('setNotification',{
                    show: true,
                    head: "Pobrano zamówienia!",
                    subheader: `Pobrano: ${this.hoodBufferOrders.length}`,
                    success: true
                    });
                }
                else
                {
                    this.hoodLoaded = true;
                    this.hoodTable = false;
                    this.$store.commit('setNotification',{
                    show: true,
                    head: "Pobieranie oczekujących zamówień nie powiodło się!",
                    subheader: `Wystąpił nieoczekiwany błąd.`,
                    success: false
                    });
                }
            },
            clearData()
            {
                this.isDoneTrackerDoc = null,
                this.lastDateToTracker = null,
                this.isDisabled = false,
                this.blTable = false,
                this.apiloTable = false,
                this.bolTable = false,
                this.wayfairTable = false,
                this.hoodTable = false,
                this.baselinkerBufferOrders = [],
                this.baselinkerMoreToLoad = false,
                this.baselinkerLastLoaded = null,
                this.apiloBufferOrders = [],
                this.apiloMoreToLoad = false,
                this.apiloLastLoaded = null,
                this.wayfairBufferOrders = [],
                this.wayfairMoreToLoad = false,
                this.wayfairLastLoaded = null,
                this.bolBufferOrders = [],
                this.bolMoreToLoad = false,
                this.bolLastLoaded = null,
                this.hoodBufferOrders = [],
                this.hoodMoreToLoad = false,
                this.hoodLastLoaded = null,
                this.queryLoading = false
            },
            async changeTab(tab, index) 
            {
                for(let i=0; i<this.tabs.length; i++)
                {
                    this.tabs[i].current = false;
                }
                this.tabs[index].current = true;
                // if(index === 0)
                // {
                //     if(this.$refs.datePickerBaselinker !== undefined)
                //     {
                //         let mDate = moment(this.$refs.datePickerBaselinker.value,"YYYY-MM-DD")
                //         if(mDate.isValid)
                //         {
                //             let unix = mDate.unix();
                //             await this.getAwaitingBaselinkerOrders(unix)
                //         }
                //     }
                // }
                if(index === 0)
                {
                    if(this.$refs.datePickerBaselinker !== undefined)
                    {
                        let mDate = moment(this.$refs.datePickerBaselinker.value,"YYYY-MM-DD")
                        if(mDate.isValid())
                        {
                            let unix = mDate.unix();
                            await this.getAwaitingOttoOrders(unix)
                        }
                        else
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Błędna data!",
                                subheader: `Popraw datę, aby kontynuować..`,
                                success: false
                            });     
                        }
                    }
                };
                if(index === 1)
                {
                    if(this.$refs.datePickerBaselinker !== undefined)
                    {
                        let mDate = moment(this.$refs.datePickerBaselinker.value, "YYYY-MM-DD");
                        if(mDate.isValid())
                        {
                            let unix = mDate.unix();
                            await this.getAwaitingApiloOrders(unix);
                        }
                        else
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Błędna data!",
                                subheader: `Popraw datę, aby kontynuować..`,
                                success: false
                            });   
                        }
                    }
                }
                else if(index === 2)
                {
                    if(this.$refs.datePickerBaselinker !== undefined)
                    {
                        let mDate = moment(this.$refs.datePickerBaselinker.value,"YYYY-MM-DD")
                        if(mDate.isValid())
                        {
                            let unix = mDate.unix();
                            await this.getAwaitingBolOrders(unix)
                        }
                        else
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Błędna data!",
                                subheader: `Popraw datę, aby kontynuować..`,
                                success: false
                            });   
                        }
                    }
                }
                else if(index === 3)
                {
                    if(this.$refs.datePickerBaselinker !== undefined)
                    {
                        let mDate = moment(this.$refs.datePickerBaselinker.value,"YYYY-MM-DD")
                        if(mDate.isValid())
                        {
                            let unix = mDate.unix();
                            await this.getAwaitingWayfairOrders(unix)
                        }
                        else
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Błędna data!",
                                subheader: `Popraw datę, aby kontynuować..`,
                                success: false
                            });   
                        }
                    }
                }
                else if(index === 4)
                {
                    if(this.$refs.datePickerBaselinker !== undefined)
                    {
                        let mDate = moment(this.$refs.datePickerBaselinker.value,"YYYY-MM-DD")
                        if(mDate.isValid())
                        {
                            let unix = mDate.unix();
                            await this.getAwaitingHoodOrders(unix)
                        }
                        else
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Błędna data!",
                                subheader: `Popraw datę, aby kontynuować..`,
                                success: false
                            });   
                        }
                    }
                }

            },
            forcedClose()
            {
                this.$router.push("/hub");
            }
        },
        beforeUnmount() {
        }
    }
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
  transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
  opacity: 0;
  }
</style>